<template>
  <!-- Hero -->
  <div id="kyc" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:my-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>KYC Verification Services</h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          HYDN privately verifies the identity of project owners to add trust
          and security to cryptocurrency platforms
        </div>
        <div class="sm:flex">
          <router-link to="/contact?q=kyc" class="button hero"
            >Get Verified</router-link
          >
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex items-center">
        <div>
          <img
            src="@/assets/images/KYC/kyc-hero.png"
            alt="HYDN"
            draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- About KYC Verification -->
  <div
    id="about-kyc"
    class="text-center text-grey-dark bg-white container-full section block-top block-bottom"
  >
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 mt-16 text-left sm:container sm:gap-8 sm:mx-auto"
    >
      <div
        v-if="AboutKYC.image"
        class="hidden col-span-12 pr-8 mt-4 md:col-span-6 lg:col-span-4 md:block"
      >
        <img class="" :src="require('@/' + AboutKYC.image)" alt="kyc" />
      </div>

      <div class="col-span-12 mt-8 md:mt-0 md:col-span-6 lg:col-start-7">
        <h2 class="text-left">{{ AboutKYC.title }}</h2>
        <TitleLine position="left" colour="blue-light" />
        <div class="grid grid-cols-12">
          <div
            v-html="AboutKYC.content"
            class="col-span-12 md:col-span-12"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- End About KYC Verification -->

  <!-- About KYC Importance -->
  <div
    id="about-kyc"
    class="text-center text-white bg-blue-background container-full section block-top block-bottom"
  >
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 mt-16 text-left sm:container sm:gap-8 sm:mx-auto"
    >
      <div class="col-span-12 mt-8 md:mt-0 md:col-span-6">
        <h2 class="text-left">{{ KYCImportance.title }}</h2>
        <TitleLine position="left" colour="blue-light" />
        <div class="grid grid-cols-12">
          <div
            v-html="KYCImportance.content"
            class="col-span-12 md:col-span-12"
          ></div>
        </div>
      </div>
      <div
        v-if="KYCImportance.image"
        class="hidden col-span-12 pr-8 mt-4 md:col-span-5 md:col-start-8 lg:col-span-4 lg:col-start-8 md:block"
      >
        <img
          class=""
          :src="require('@/' + KYCImportance.image)"
          alt="kyc warning"
        />
      </div>
    </div>
  </div>
  <!-- End About KYC Importance -->

  <!-- Banner -->

  <div
    class="grid items-center grid-cols-12 py-12 mt-12 border-2 sm:mt-16 border-x-0 border-y-white bg-blue"
  >
    <img
      src="@/assets/images/security/audit-lock.png"
      alt="KYC Verification"
      class="col-span-1 col-start-3 mx-auto"
    />
    <h4 class="col-span-7 ml-12 text-3xl text-left md:text-4xl lg:text-5xl">
      KYC Verification adds an extra layer of trust to your platform without
      having to reveal your identity to the world
    </h4>
  </div>
  <!-- End Banner -->

  <!-- Verification Process -->
  <div
    id="verification"
    class="container px-12 text-center text-white section block-top block-bottom bg-blue-background"
  >
    <h2 class="text-center">How KYC Verification Works</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 gap-0 mt-16">
      <!-- Verification Process card -->
      <div
        v-for="VerificationProcessCard in VerificationProcess"
        :key="VerificationProcessCard.id"
        class="col-span-12 md:flex"
      >
        <div class="grid grid-cols-12">
          <div
            class="col-span-12 col-start-1 p-8 mb-6 text-left bg-white md:col-start-4 md:col-span-6 rounded-xl text-blue-background"
          >
            <div
              class="inline-block pr-2 mb-0 -ml-1 text-5xl font-black lg:text-left sm:text-4xl lg:text-5xl"
            >
              {{ VerificationProcessCard.id }}.
            </div>
            <h3 class="inline-block mb-4 text-2xl sm:text-2xl lg:text-3xl">
              {{ VerificationProcessCard.title }}
            </h3>
            <div class="flex">
              <p class="text-base">
                {{ VerificationProcessCard.content }}
              </p>
            </div>
          </div>
          <div
            v-if="VerificationProcessCard.id != 4"
            class="items-center col-span-12 col-start-1 mx-auto mb-6 text-center rotate-90"
          >
            <img
              class="w-8 mx-4"
              src="@/assets/icons/arrow-right.svg"
              alt="navigation right"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Verification Process card -->
  </div>
  <!-- End Verification Process -->

  <!-- Partners -->
  <div
    id="partners"
    class="container-full block-top block-bottom mx-auto bg-white text-grey-dark bg-[url('@/assets/images/global/world-map.svg')] bg-left bg-contain bg-no-repeat min-h-screen"
  >
    <div
      class="py-24 sm:shadow-lg sm:mx-8 md:mx-24 b sm:rounded-2xl glass section"
    >
      <h2 class="text-center">Partners</h2>
      <TitleLine position="center" colour="blue-light" />
      <div
        class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto sm:text-center"
      >
        <!-- Partners card -->
        <div
          v-for="PartnersCard in PartnersCards"
          :key="PartnersCard.id"
          class="col-span-12 px-12 md:col-span-6 lg:col-span-4"
        >
          <a
            :href="PartnersCard.ctaURL"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              class="content-center px-8 py-8 text-white duration-300 rounded-md sm:px-12 hover:-mt-1 hover:shadow-xl bg-blue-background/80 backdrop-blur-sm hover:bg-blue/80 justify-items-center"
            >
              <img
                class="mx-auto"
                :src="require('@/' + PartnersCard.logo)"
                alt="partner"
                width="600"
                height="200"
              />
            </div>
          </a>
        </div>
        <!-- Partners Card-->
      </div>
    </div>
  </div>
  <!-- Partners -->

  <!-- Why HYDN -->
  <div
    id="why-hydn"
    class="container px-4 text-left text-white section block-top block-bottom bg-blue-background sm:text-center"
  >
    <h2 class="text-center">Why HYDN</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 gap-2 mx-auto sm:container">
      <!-- Why HYDN card -->
      <div
        v-for="WhyHYDNCard in WhyHYDN"
        :key="WhyHYDNCard.id"
        :class="{
          'sm:col-start-4 lg:col-start-0 col-span-12': WhyHYDNCard.id === 3,
        }"
        class="col-span-12 lg:col-span-4 sm:col-span-6"
      >
        <div class="flex justify-center text-left sm:text-center">
          <div class="block text-white md:p-8 md:mb-4 md:rounded-xl sm:grid">
            <img
              class="block float-left w-12 h-12 mb-32 mr-4 sm:mb-6 sm:float-none sm:mx-auto"
              src="@/assets/icons/tick.svg"
              alt="tick"
            />
            <h3 class="mb-2 text-3xl">
              {{ WhyHYDNCard.title }}
            </h3>
            <div class="flex-1">
              <p class="text-base">
                {{ WhyHYDNCard.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Why HYDN card -->
  </div>
  <!-- End Why HYDN -->
  <!-- Bottom CTA  -->
  <div class="py-4 bg-white container-full">
    <div class="px-8 py-12 mx-auto text-center">
      <span
        class="block pb-8 text-3xl font-extrabold text-blue-background sm:text-4xl md:pr-8 md:pb-0 md:inline-block sm:align-middle"
        >Looking for KYC Verification?</span
      >
      <router-link
        to="/contact?q=kyc"
        class="block button hero sm:inline-block sm:align-middle"
        >Request a Quote</router-link
      >
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import ServiceCards from "../data/KYC/ServiceCards.json";
import VerificationProcess from "../data/KYC/VerificationProcess.json";
import AboutKYC from "../data/KYC/AboutKYC.json";
import KYCImportance from "../data/KYC/KYCImportance.json";
import PartnersCards from "../data/Global/PartnersCards.json";
import WhyHYDN from "../data/KYC/WhyHYDN.json";

import TitleLine from "../components/utilities/TitleLine.vue";

export default {
  components: {
    TitleLine,
  },
  data() {
    return {
      ServiceCards: ServiceCards,
      VerificationProcess: VerificationProcess,
      AboutKYC: AboutKYC,
      KYCImportance: KYCImportance,
      PartnersCards: PartnersCards,
      WhyHYDN: WhyHYDN,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  @apply w-full;
  img {
    @apply invert-[.30] duration-300 hover:invert-0 w-full max-h-[42px];
  }
}
</style>
