<template>
  <div class="flex items-center justify-center bg-blue-background py-40">
    <div class="px-40 py-20 bg-white rounded-md shadow-xl">
      <div class="flex flex-col items-center">
        <h1 class="font-extrabold text-blue-light text-9xl">404</h1>

        <h6
          class="mb-2 text-2xl font-extrabold text-center text-gray-800 md:text-3xl"
        >
          <span class="text-red">Oops!</span> Page not found
        </h6>

        <p class="mb-8 text-center text-gray-500 md:text-lg">
          The page you’re looking for doesn’t exist.
        </p>

        <router-link
          to="/"
          class="px-6 py-2 text-sm font-semibold text-white bg-blue-light opacity-80 hover:opacity-100 duration-300"
          >Go home</router-link
        >
      </div>
    </div>
  </div>
</template>
