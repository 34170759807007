<template>
  <!-- Hero -->
  <div id="blog" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:mt-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>Blog</h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          Keep up to date with the latest HYDN news, announcements, completed
          audits, and more with our blog
        </div>
        <div class="sm:flex">
          <a href="#articles" class="button hero">Read Articles</a>
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex md:items-center">
        <div>
          <Vue3Lottie class="max-h-[300px]" :animationData="BlogHero" />
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- Blog -->
  <div
    id="blog"
    class="px-6 text-left md:mt-32 container-full bg-blue-dark section block-top block-bottom sm:text-center"
  >
    <div class="max-w-screen-lg mx-auto">
      <h2 class="text-left">Articles</h2>
      <TitleLine position="left" colour="blue-light" />
      <div id="articles" class="grid gap-8 mt-16 text-left text-grey-dark team">
        <!-- Blog Card -->
        <div
          v-for="Article in Articles.slice().reverse()"
          :key="Article.id"
          class="grid"
        >
          <div class="flex justify-center">
            <div
              class="grid w-full grid-cols-12 gap-4 py-12 pl-1 pr-2 bg-white md:p-12 rounded-xl md:gap-8"
            >
              <div
                v-if="Article.image"
                class="col-span-12 ml-6 md:col-span-3 md:block"
              >
                <a
                  v-if="Article.url"
                  :href="Article.url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="p-2 shadow-xl rounded-2xl"
                    :src="require('@/' + Article.image)"
                    width="500"
                    height="500"
                    alt="article"
                /></a>
              </div>

              <div class="col-span-12 col-start-2 pr-6 md:mt-0 md:col-span-8">
                <div class="grid grid-cols-12">
                  <h3 class="col-span-12 mt-4 mb-2">
                    <a
                      v-if="Article.url"
                      :href="Article.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ Article.title }}</a
                    >
                  </h3>
                  <div
                    v-html="Article.date"
                    class="col-span-12 mb-4 text-sm md:col-span-12"
                  ></div>
                  <div
                    v-html="Article.excerpt"
                    class="col-span-12 md:col-span-12"
                  ></div>
                  <div class="col-span-12 mt-4 socials md:col-span-6">
                    <a
                      v-if="Article.url"
                      :href="Article.url"
                      target="_blank"
                      class="button button__large"
                      rel="noopener noreferrer"
                    >
                      Read Full Article
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Blog Card -->
    </div>
  </div>
  <!-- End Blog -->

  <!-- Bottom CTA  -->
  <div class="py-4 bg-white container-full">
    <div class="px-6 py-12 mx-auto text-center">
      <span
        class="block pb-8 text-3xl font-extrabold text-blue-background sm:text-4xl md:pr-8 md:pb-0 md:inline-block sm:align-middle"
        >Looking for a Smart Contract Audit?</span
      >
      <router-link
        to="/contact?q=contract-audit"
        class="block button hero sm:inline-block sm:align-middle"
        >Request a Quote</router-link
      >
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import Articles from "../data/Blog/Articles.json";

import TitleLine from "../components/utilities/TitleLine.vue";
import { Vue3Lottie } from "vue3-lottie";

import * as BlogHero from "../assets/images/blog/lottie-blog-hero.json";

export default {
  components: {
    TitleLine,
    Vue3Lottie,
  },
  data() {
    return {
      Articles: Articles,

      BlogHero,
      animationSpeed: 1,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  @apply w-full;
  img {
    @apply invert-[.30] duration-300 hover:invert-0 w-full max-h-[42px];
  }
}
</style>
