<template>
  <div class="container container-full">
    <nav>
      <div class="md:flex md:items-center">
        <div class="flex justify-between">
          <router-link to="/" class="text-4xl font-extrabold remove-underline">
            <img
              class="float-left max-w-[200px]"
              src="@/assets/images/hydn-logo.svg"
              alt="hydn logo"
              width="250"
              height="44"
            />
          </router-link>
          <button
            class="px-3 py-2 text-white duration-300 border-solid rounded opacity-70 hover:opacity-100 md:hidden"
            id="navbar-toggle"
          >
            <img
              src="@/assets/icons/nav-burger.svg"
              alt="navigation menu"
              width="19"
              height="16"
            />
          </button>
        </div>
        <div
          class="flex-col hidden mt-6 md:flex md:flex-row md:ml-auto md:mt-0"
          id="navbar-collapse"
        >
          <ul class="flex flex-col select-none divider md:flex-row">
            <li
              v-on:click="toogleDropDown"
              v-click-away="onClickAway"
              class="relative list-none"
            >
              <button class="nav__link">
                Services
                <svg
                  class="float-right w-5 h-5 mt-0.5 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <div v-show="dropDownOpen" class="inset-0 z-10 min-w-100"></div>

              <div
                v-show="dropDownOpen"
                class="left-0 z-20 px-2 rounded-md shadow-xl w-72 md:absolute md:py-2 md:mt-2 dropdown md:bg-white/10 md:backdrop-blur-lg divider"
              >
                <router-link to="security" class="block nav__link"
                  >Security Audit</router-link
                >
                <router-link to="hydnseekers" class="block nav__link"
                  >HYDN Seekers</router-link
                >
                <router-link to="tokenomics" class="block nav__link"
                  >Tokenomics & Consultancy</router-link
                >
              </div>
            </li>
            <li class="flex list-none">
              <router-link to="/partners" class="block nav__link"
                >Partners</router-link
              >
            </li>
            <li class="flex list-none">
              <router-link to="/blog" class="block nav__link">Blog</router-link>
            </li>
            <li class="flex list-none">
              <router-link to="/careers" class="block nav__link"
                >Careers</router-link
              >
            </li>
            <li class="flex list-none">
              <router-link to="/about" class="block nav__link"
                >About Us</router-link
              >
            </li>
            <li class="flex list-none pl-0 pt-4 md:pl-4 md:pt-0">
              <router-link id="nav-link" to="contact" class="block button">
                Request a Quote
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- end nav -->
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: this.$router.options.routes,
      dropDownOpen: false,
    };
  },
  methods: {
    toogleDropDown() {
      this.dropDownOpen = !this.dropDownOpen;
    },
    onClickAway() {
      if (this.dropDownOpen) {
        this.dropDownOpen = !this.dropDownOpen;
      }
    },
  },
  name: "Header",
  props: {
    msg: String,
  },

  mounted() {
    let toggleBtn = document.querySelector("#navbar-toggle, #nav-link");
    let collapse = document.querySelector("#navbar-collapse");

    toggleBtn.onclick = () => {
      collapse.classList.toggle("hidden");
      collapse.classList.toggle("flex");
    };
  },
};
</script>

<style scoped lang="scss">
nav,
.nav {
  @apply pt-8;
  &__link {
    @apply block p-2 tracking-wide text-grey-light duration-300 xl:mx-2 lg:mx-1 hover:text-white sm:hover:underline underline-offset-8 cursor-pointer pt-3;
  }
  &__hide {
    @apply md:hidden;
  }
}
.dropdown {
  .nav__link {
    @apply hover:indent-0.5 hover:no-underline;
  }
  .router-link-active {
    @apply hover:indent-0;
  }
}
.router-link-active {
  @apply text-white hover:no-underline cursor-default;
}
</style>
