<template>
  <!-- Hero -->
  <div id="security" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:my-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>
          Tokenomics consultancy from a team of leading blockchain experts
        </h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          HYDN provides advisory services for digital asset companies, including
          tokenomics consultancy, go-to-market strategy, and crypto
          infrastructure consultancy
        </div>
        <div class="sm:flex">
          <router-link to="/contact?q=tokenomics" class="button hero"
            >Request a Quote</router-link
          >
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex items-center">
        <div>
          <Vue3Lottie :animationData="TokenomicsHero" />
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- Services -->
  <div
    id="services"
    class="text-center bg-white text-grey-dark section container-full block-top block-bottom"
  >
    <h2>Tokenomics Services</h2>
    <TitleLine position="center" colour="blue-light" />
    <!-- service card loop 1 -->
    <div
      class="grid max-w-screen-xl grid-cols-6 gap-2 mx-4 mt-16 text-center sm:container sm:gap-8 sm:mx-auto"
    >
      <div
        v-for="ServiceCard in ServiceCards.slice(0, 3)"
        :key="ServiceCard.id"
        class="flex col-span-6 md:col-span-2"
      >
        <div
          class="flex flex-col px-8 py-16 text-white rounded-none shadow-2xl sm:px-12 md:rounded-md bg-blue-background"
        >
          <h5 class="mb-2 font-extrabold uppercase">
            {{ ServiceCard.title }}
          </h5>
          <p class="flex-1">
            {{ ServiceCard.subtitle }}
          </p>
          <router-link to="/contact" class="button button__large"
            >{{ ServiceCard.cta }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- End service card loop 2 -->
  </div>
  <!-- End Services -->

  <!-- Banner -->
  <div
    class="grid items-center grid-cols-12 py-8 mt-12 border-2 md:py-12 sm:mt-16 border-x-0 border-y-white bg-blue"
  >
    <img
      src="@/assets/images/security/audit-lock.webp"
      alt="KYC Verification"
      class="col-span-2 col-start-2 py-4 mx-auto md:col-start-3 md:col-span-1"
      width="149"
      height="200"
    />
    <h4 class="col-span-7 ml-12 text-2xl text-left sm:text-4xl lg:text-5xl">
      In 2022, worldwide spending on blockchain solutions will reach $11.7
      billion
    </h4>
  </div>
  <!-- End Banner -->

  <!-- The Delivery Team -->
  <div
    id="leadership"
    class="container pb-24 text-left section block-top bg-blue-background sm:text-center"
  >
    <h2 class="text-center">The Delivery Team</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid gap-8 mt-16 text-left text-grey-dark team">
      <!-- The Delivery Team Card -->
      <div class="grid">
        <div class="flex justify-center">
          <div
            class="grid w-full grid-cols-12 gap-4 px-6 py-12 bg-white md:p-12 rounded-xl md:gap-8"
          >
            <div class="col-span-12 mt-8 md:mt-0 md:col-span-6">
              <div class="grid grid-cols-12">
                <h3 class="col-span-12 mb-4">
                  {{ DeliveryTeam.title }}
                </h3>
                <div
                  class="col-span-12 mt-4 md:col-span-12"
                  v-html="DeliveryTeam.content"
                ></div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <img
                class="rounded-lg"
                src="@/assets/images/tokenomics/leadership.webp"
                alt="leadership"
                width="6100"
                height="4067"
              />
            </div>
            <div class="col-span-12 mt-8 md:mt-0 md:col-span-12">
              <div
                class="grid grid-cols-6 gap-2 md:grid-cols-10 sm:gap-4 md:gap-8"
              >
                <img
                  src="@/assets/images/tokenomics/Citi.svg"
                  class="col-span-2 p-4 mb-2"
                  alt="tokenomics"
                  width="1000"
                  height="400"
                />
                <img
                  src="@/assets/images/tokenomics/Bosch.svg"
                  class="col-span-2 p-4 mb-2"
                  alt="Bosch"
                  width="1000"
                  height="400"
                />
                <img
                  src="@/assets/images/tokenomics/FirstGroup.svg"
                  class="col-span-2 p-4 mb-2"
                  alt="tokenomics 1"
                  width="1000"
                  height="400"
                />
                <img
                  src="@/assets/images/tokenomics/Arriva.svg"
                  class="col-span-2 p-4 mb-2"
                  alt="Arriva"
                  width="1000"
                  height="400"
                />
                <img
                  src="@/assets/images/tokenomics/RoyalParkPartners.svg"
                  class="col-span-2 p-4 mb-2"
                  alt="RoyalPark"
                  width="1000"
                  height="400"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End The Delivery Team Card -->
  </div>
  <!-- End The Delivery Team -->

  <!-- Partners -->
  <div
    id="partners"
    class="container-full block-top block-bottom mx-auto bg-white text-grey-dark bg-[url('@/assets/images/global/world-map.svg')] bg-left bg-contain bg-no-repeat min-h-screen"
  >
    <div
      class="py-24 sm:shadow-lg sm:mx-8 md:mx-24 b sm:rounded-2xl glass section"
    >
      <h2 class="text-center">Partners</h2>
      <TitleLine position="center" colour="blue-light" />
      <div
        class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto sm:text-center"
      >
        <!-- Partners card -->
        <div
          v-for="PartnersCard in PartnersCards"
          :key="PartnersCard.id"
          class="col-span-12 px-12 md:col-span-6 lg:col-span-4"
        >
          <a
            :href="PartnersCard.ctaURL"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              class="content-center px-8 py-8 text-white duration-300 rounded-md sm:px-12 hover:-mt-1 hover:shadow-xl bg-blue-background/80 backdrop-blur-sm hover:bg-blue/80 justify-items-center"
            >
              <img
                class="mx-auto"
                :src="require('@/' + PartnersCard.logo)"
                width="600"
                height="200"
                alt="partner"
              />
            </div>
          </a>
        </div>
        <!-- Partners Card-->
      </div>
    </div>
  </div>
  <!-- Partners -->

  <!-- Why HYDN -->
  <div
    id="why-hydn"
    class="container px-4 text-left text-white section block-top block-bottom bg-blue-background sm:text-center"
  >
    <h2 class="text-center">Why HYDN?</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 mx-auto sm:container">
      <!-- Why HYDN card -->
      <div
        v-for="WhyHYDNCard in WhyHYDN"
        :key="WhyHYDNCard.id"
        :class="{
          'sm:col-start-4 lg:col-start-0 col-span-12': WhyHYDNCard.id === 3,
        }"
        class="col-span-12 lg:col-span-4 sm:col-span-6"
      >
        <div class="flex justify-center text-left sm:text-center">
          <div class="block text-white md:p-8 sm:grid">
            <img
              class="block float-left w-12 h-12 mb-32 mr-4 sm:mb-6 sm:float-none sm:mx-auto"
              src="@/assets/icons/tick.svg"
              alt="tick"
            />
            <h3 class="mb-2 text-3xl">
              {{ WhyHYDNCard.title }}
            </h3>
            <div class="flex-1">
              <p class="text-base">
                {{ WhyHYDNCard.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Why HYDN card -->
  </div>
  <!-- End Why HYDN -->
  <!-- Bottom CTA  -->
  <div class="py-4 bg-white container-full">
    <div class="px-8 py-12 mx-auto text-center">
      <span
        class="block pb-8 text-3xl font-extrabold text-blue-background sm:text-4xl md:pr-8 md:pb-0 md:inline-block sm:align-middle"
        >Looking for Tokenomics Consultancy?</span
      >
      <router-link
        to="/contact?q=tokenomics"
        class="block button hero sm:inline-block sm:align-middle"
        >Request a Quote</router-link
      >
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import ServiceCards from "../data/Tokenomics/ServiceCards.json";
import AuditProcess from "../data/Tokenomics/AuditProcess.json";
import DeliveryTeam from "../data/Tokenomics/DeliveryTeam.json";
import PartnersCards from "../data/Global/PartnersCards.json";
import WhyHYDN from "../data/Tokenomics/WhyHYDN.json";

import TitleLine from "../components/utilities/TitleLine.vue";
import { Vue3Lottie } from "vue3-lottie";

import * as TokenomicsHero from "../assets/images/tokenomics/lottie-tokenomics-hero.json";

export default {
  components: {
    TitleLine,
    Vue3Lottie,
  },
  data() {
    return {
      ServiceCards: ServiceCards,
      AuditProcess: AuditProcess,
      DeliveryTeam: DeliveryTeam,
      PartnersCards: PartnersCards,
      WhyHYDN: WhyHYDN,
      TokenomicsHero,
      animationSpeed: 1,
    };
  },
};
</script>

<style scoped></style>
