<template>
  <Header msg="Header" />
  <router-view :key="$route.fullPath" />
  <vue-cookie-comply
    @on-accept-all-cookies="onAccept"
    headerTitle=""
    headerDescription="We use cookies to improve your experience on our site. By remaining on the site, you consent to our use of cookies."
    acceptAllLabel="Accept"
    preferencesLabel=""
  >
  </vue-cookie-comply>
  <Footer msg="Footer" />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  methods: {
    onAccept: () => {},
  },
};
</script>

<style lang="scss">
@font-face {
  src: url(./assets/fonts/Inter.ttf) format("truetype");
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
}

:root {
  --xs-rem: 0.702rem;
  --sm-rem: 0.889rem;
  --base-rem: 1rem;
  --lg-rem: 1.125rem;
  --xl-rem: 1.266rem;
  --2xl-rem: 1.424rem;
  --3xl-rem: 1.602rem;
  --4xl-rem: 1.802rem;
  --5xl-rem: 2.027rem;
  --6xl-rem: 2.281rem;
  --7xl-rem: 2.566rem;
  --8xl-rem: 2.887rem;

  --xs: 0.702;
  --sm: 0.889;
  --base: 1;
  --lg: 1.125;
  --xl: 1.266;
  --2xl: 1.424;
  --3xl: 1.602;
  --4xl: 1.802;
  --5xl: 2.027;
  --6xl: 2.281;
  --7xl: 2.566;
  --8xl: 2.887;
}

body {
  @apply bg-blue-background text-white;
  font-family: "Inter";
}
.form__input {
  @apply text-sm;
}

p {
  @apply mb-4;
  a {
    @apply underline-offset-4;
    font-weight: 600;
    font-variation-settings: "wght" 600;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      opacity: 80%;
      transition: 300ms;
    }
  }
  ul {
    @apply my-4;
  }
  li {
    @apply list-disc mt-2;
  }
  &.quote {
    @apply italic text-lg md:mx-4 pb-4 ml-4 md:pt-0 pt-4 font-light;
  }
}
ul {
  @apply list-inside mb-4;
}
.container {
  @apply items-center content-center;
  &-full {
    @apply w-full;
  }
}
.block {
  &-bottom {
    @apply pb-12 md:pb-16;
  }
  &-top {
    @apply pt-12 md:pt-16;
  }
}

.text-6xl {
  font-size: clamp(
    var(--6xl-rem),
    calc(
      var(--6xl-rem) + (var(--8xl) - var(--6xl)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--8xl-rem)
  );
}

.text-5xl {
  font-size: clamp(
    var(--5xl-rem),
    calc(
      var(--5xl-rem) + (var(--7xl) - var(--5xl)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--7xl-rem)
  );
}

.text-4xl {
  font-size: clamp(
    var(--3xl-rem),
    calc(
      var(--3xl-rem) + (var(--5xl) - var(--3xl)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--5xl-rem)
  );
}

.text-3xl {
  font-size: clamp(
    var(--xl-rem),
    calc(
      var(--xl-rem) + (var(--3xl) - var(--xl)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--3xl-rem)
  );
}

.text-2xl {
  font-size: clamp(
    var(--lg-rem),
    calc(
      var(--lg-rem) + (var(--2xl) - var(--lg)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--2xl-rem)
  );
}

.text-xl {
  font-size: clamp(
    var(--base-rem),
    calc(
      var(--base-rem) + (var(--xl) - var(--base)) *
        ((100vw - 20rem) / (96 - 20))
    ),
    var(--xl-rem)
  );
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

.text-lg {
  font-size: clamp(
    var(--base-rem),
    calc(
      var(--base-rem) + (var(--lg) - var(--base)) *
        ((100vw - 20rem) / (96 - 20))
    ),
    var(--lg-rem)
  );
}
.text-base {
  font-size: clamp(
    var(--sm-rem),
    calc(
      var(--sm-rem) + (var(--base) - var(--sm)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--base-rem)
  );
}

.text-sm {
  font-size: clamp(
    var(--xs-rem),
    calc(
      var(--xs-rem) + (var(--sm) - var(--xs)) * ((100vw - 20rem) / (96 - 20))
    ),
    var(--sm-rem)
  );
}

h1 {
  @apply text-6xl mb-8 tracking-wide leading-tight;
  font-weight: 400;
  font-variation-settings: "wght" 900;
}

h2 {
  @apply text-5xl leading-normal mb-8;
  font-weight: 400;
  font-variation-settings: "wght" 700;
}
h3 {
  @apply text-4xl leading-tight mb-4;
  font-weight: 400;
  font-variation-settings: "wght" 700;
}
h4 {
  @apply text-xl sm:text-2xl md:text-3xl leading-normal;
  font-weight: 400;
  font-variation-settings: "wght" 500;
}
h5 {
  @apply text-2xl leading-normal;
  font-weight: 400;
  font-variation-settings: "wght" 600;
}
.button {
  @apply text-center px-6 lg:px-12 py-3 text-lg md:text-lg tracking-wide text-white duration-300 rounded-md remove-underline bg-blue-light opacity-100 hover:opacity-80 cursor-pointer;
  font-weight: 450;
  font-variation-settings: "wght" 650;
  &.button__large {
    @apply block;
  }
  &.hero {
    @apply py-4 px-16 sm:px-24;
  }
}
svg {
  @apply fill-white;
}

.glass {
  @apply bg-white md:bg-white/70 md:backdrop-blur-lg border border-white;
}
.remove-underline {
  text-decoration: none !important;
}
.divider {
  @apply divide-y md:divide-y-0 divide-dashed divide-white/20;
}
.cookie-comply {
  @apply text-blue-background py-4 px-8 fixed bottom-0;
  &__actions {
    @apply gap-0;
    button {
      @apply bg-blue-light duration-300 uppercase col-start-2;
      opacity: 80%;
      &:hover {
        opacity: 100%;
      }
      &:first-child {
        @apply hidden;
      }
    }
  }
}
.agile {
  &__nav-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    height: 100%;
    position: absolute;
    top: 0;
    transition-duration: 0.3s;
    width: 60px;
    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }
    &:hover {
      background-color: rgba(#000, 0.5);
      opacity: 1;
    }
  }
  &__slide {
    @apply select-none opacity-0;
    &--active {
      @apply opacity-100;
    }
  }
  &__dots {
    bottom: 10px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  &__dot {
    margin: 0 10px;
    button {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 15px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 2em;
      transition-duration: 0.3s;
      width: 15px;
    }
    &--current,
    &:hover {
      button {
        background-color: #fff;
      }
    }
  }
  &__actions {
    @apply justify-center;
  }
}
</style>
