<template>
  <div
    class="w-full bg-white text-grey-dark md:my-16 sm:my-0"
    v-if="isSubmitted"
  >
    <div
      class="flex flex-col items-start justify-start w-full p-10 mx-auto text-center lg:p-16 xl:p-24"
    >
      <h4 class="w-full text-3xl font-extrabold text-center text-grey-dark">
        Form Submitted Successfully
      </h4>
      <h5 class="w-full mt-4 text-center">
        We will be in touch soon. Thank you.
      </h5>
      <div class="w-full mt-8">
        <router-link to="/" class="button">Home</router-link>
      </div>
    </div>
  </div>
  <form
    @submit="onSubmit"
    class="relative w-full space-y-8"
    v-if="!isSubmitted"
    id="container"
  >
    <h4 class="w-full text-4xl font-extrabold text-grey-dark">Contact Us</h4>
    <h5 class="mt-4 text-lg">
      Please fill out the form and a member of our team will get back to you as
      soon as possible. Thank you.
    </h5>
    <div class="relative required">
      <label class="font-medium text-grey-dark">Enquiry</label>
      <select
        required
        class="block pr-4 form__select"
        v-model="enquirySelected"
      >
        <!--        @change="switchEnquirySelect"-->
        <option
          v-for="enquiryOption in enquiryOptions"
          :key="enquiryOption.id"
          :value="enquiryOption.id"
        >
          {{ enquiryOption.label }}
        </option>
      </select>
    </div>

    <div class="relative required">
      <label class="font-medium text-grey-dark">Your Name</label>
      <input
        required
        name="name"
        v-model="name"
        type="text"
        class="form__input form-input"
        placeholder="Enter Your Name"
      />
    </div>
    <div class="relative required">
      <label class="font-medium text-grey-dark">Preferred Contact Method</label>
      <select
        required
        class="block pr-4 form__select"
        v-model="contactMethodSelected"
        @change="switchContactMethodSelect"
      >
        <option
          v-for="item in contactMethodOptions"
          :key="item.id"
          :value="item.id > 1 ? item.id : ''"
          :disabled="item.disabled"
        >
          {{ item.type }}
        </option>
      </select>
    </div>
    <div v-if="contactSelected > 1" class="relative required">
      <label class="font-medium text-grey-dark">{{
        contactMethodOptions[contactSelected - 1].label
      }}</label>
      <input
        required
        name="contact"
        v-model="contact"
        :type="contactMethodOptions[contactSelected - 1].formType"
        class="form__input form-input"
        :placeholder="contactMethodOptions[contactSelected - 1].label"
      />
    </div>

    <div class="relative required">
      <label class="font-medium text-grey-dark">Project Name</label>
      <input
        required
        name="project"
        v-model="projectName"
        type="text"
        class="form__input form-input"
        placeholder="Enter Your Project Name"
      />
    </div>
    <div class="relative">
      <label class="font-medium text-grey-dark">Message</label>
      <label class="block text-xs text-grey-dark"
        >Any specific details? Are there certain deadlines?</label
      >
      <textarea
        required
        name="message"
        minlength="100"
        v-model="message"
        class="form__textarea form-textarea"
        placeholder="Enter Your Message"
      ></textarea>
    </div>
    <div class="relative">
      <button
        :disabled="isSubmitting"
        type="submit"
        class="w-full button button__large"
      >
        <img
          v-if="isSubmitting"
          src="@/assets/icons/spinner.svg"
          alt="spinner"
          width="16"
          height="16"
          class="animate-spin inline mr-2"
        />
        Submit
      </button>
    </div>
  </form>
</template>

<script>
export default {
  components: {},
  methods: {
    onSubmit(event) {
      event.preventDefault();
      // console.log(`
      // enquirySelected: ${this.enquirySelected}
      // name: ${this.name}
      // contactMethodSelected: ${
      //   this.contactMethodOptions.find(
      //     (option) => option.id === this.contactMethodSelected
      //   ).type
      // }
      // contact: ${this.contact}
      // projectName: ${this.projectName}
      // message: ${this.message}
      // `);
      this.isSubmitting = true;
      fetch("/api/enquiry", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          enquiry: this.enquiryOptions.find(
            (option) => option.id === this.enquirySelected
          ).label,
          name: this.name,
          contactMethod: this.contactMethodOptions.find(
            (option) => option.id === this.contactMethodSelected
          ).type,
          contact: this.contact,
          projectName: this.projectName,
          message: this.message,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.isSubmitting = false;
          this.isSubmitted = true;
          const container = document.querySelector("#container");
          container.scrollIntoView({ behavior: "smooth" });
        })
        .catch((error) => {
          console.error("Failed to send enquiry", error);
          this.isSubmitting = false;
        });
    },
    // switchEnquirySelect(event) {
    //   this.$router.replace({
    //     name: this.$route.params.name,
    //     query: { q: event.target.value },
    //   });
    // },
    switchContactMethodSelect(event) {
      this.contactSelected = event.target.value;
    },
  },
  mounted() {
    if (
      this.enquiryOptions.find((enquiry) => enquiry.id === this.$route.query.q)
    ) {
      this.enquirySelected = this.$route.query.q;
    }
  },
  data() {
    return {
      isSubmitting: false,
      isSubmitted: false,
      enquirySelected: "general-query",
      enquiryOptions: [
        { id: "general-query", label: "General Query" },
        { id: "smart-contract-audit", label: "Smart Contract Audit" },
        { id: "tokenomics", label: "Tokenomics" },
        { id: "kyc", label: "KYC" },
        { id: "hydn-seekers", label: "HYDN Seekers" },
      ],
      name: "",
      contactSelected: "general-query",
      contactMethodSelected: "",
      contactMethodOptions: [
        {
          id: 1,
          formType: "text",
          type: "Select...",
          label: "Select...",
          disabled: true,
        },
        {
          id: 2,
          formType: "email",
          type: "Email",
          label: "Enter Your Email Address",
        },
        {
          id: 3,
          formType: "text",
          type: "Telegram",
          label: "Enter Your Telegram Username",
        },
        {
          id: 4,
          formType: "text",
          type: "Discord",
          label: "Enter Your Discord Username",
        },
        {
          id: 5,
          formType: "text",
          type: "Twitter",
          label: "Enter Your Twitter Username",
        },
      ],
      contact: "",
      projectName: "",
      message: "",
    };
  },
};
</script>
