<template>
  <!-- Hero -->
  <div id="security" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:my-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>Partners</h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          HYDN is partnered with a number of organisations in both the
          blockchain and business worlds. These partnerships help bring value to
          both our clients and our partners.
        </div>
        <div class="sm:flex">
          <router-link to="/contact?q=smart-contract-audit" class="button"
            >Enquire about partnerships</router-link
          >
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex items-center">
        <Vue3Lottie :animationData="PartnersHero" />
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- Why Partner -->
  <div
    id="services"
    class="text-center bg-white text-grey-dark section container-full block-top block-bottom"
  >
    <h2>Why Partner with HYDN?</h2>
    <TitleLine position="center" colour="blue-light" />
    <!-- service card loop 1 -->
    <div
      class="grid max-w-screen-xl grid-cols-6 gap-2 mx-4 mt-16 text-left sm:container sm:gap-4 sm:mx-auto"
    >
      <div
        v-for="WhyPartner in WhyPartnerCards"
        :key="WhyPartner.id"
        class="flex col-span-6 md:col-span-2"
      >
        <div
          class="flex flex-col px-4 py-8 text-white rounded-none shadow-2xl sm:px-8 md:rounded-md bg-blue-background"
        >
          <h5 class="mb-2">
            {{ WhyPartner.title }}
          </h5>
          <p class="flex-1">
            {{ WhyPartner.subtitle }}
          </p>
        </div>
      </div>
    </div>
    <!-- End service card loop 2 -->
  </div>
  <!-- End Why Partner -->

  <!-- Launchpad -->

  <div
    id="launchpad"
    class="p-8 mx-auto text-white bg-blue-background container-full block-top block-bottom"
  >
    <h2 class="text-center">Launchpad Partners</h2>
    <TitleLine
      position="center"
      colour="blue-light"
      class="line-left-mobile-center"
    />
    <div class="grid grid-cols-7 gap-0 mx-auto section">
      <div class="col-span-12 md:col-span-3 lg:col-span-4 md:grid">
        <div class="flex flex-col items-center justify-center">
          <img
            src="@/assets/images/global/partners/oracle.svg"
            alt="Launchpad Partners"
            width="600"
            height="200"
            draggable="false"
          />
        </div>
      </div>

      <div
        class="flex items-center justify-center col-span-12 text-left sm:p-24 md:p-16 lg:p-16 sm:col-span-12 md:col-span-4 lg:col-span-3"
      >
        <div>
          <h3>Oracle Finance</h3>
          <p class="text-base">
            HYDN is partnered with Oracle Finance to provide Smart Contract
            Audits for projects coming through their launchpad.
          </p>
          <p>
            Oracle Finance is a financial platform that facilitates a multitude
            of cryptocurrency investing and value creation by building a
            treasury-backed, yield-generating currency, for the “next billion
            users”. Their goal is to empower the investor and make it as easy,
            efficient, and fast as possible to manage their assets, lend &
            borrow, earn interest on staking, bridge between chains, launch new
            projects including NFTs, and much more.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- End Launchpad -->

  <!-- Partners -->
  <div
    id="Partners"
    class="px-4 text-left bg-white text-grey-dark container-full lg:px-24 section block-top block-bottom sm:text-center"
  >
    <h2 class="text-center">Strategic Partners</h2>
    <TitleLine position="center" colour="blue-light" />
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto sm:text-center"
    >
      <div
        class="flex items-center justify-center col-span-12 text-left md:col-span-8 md:col-start-3"
      >
        <div>
          <p>
            HYDN is partnered with a number of strategic partners within both
            blockchain and the business world. We work closely with our partners
            to bring our clients best-in-class Go-to-Market strategy, Crypto
            Infrastructure consultancy, and Tokenomics consultancy.
          </p>
          <p>
            Our partners include McCann Dublin, a full service creative agency
            who have worked with the likes of Cardano, Microsoft, and AB inBev.
          </p>
          <p>
            We work with Fireblocks to provide crypto and digital asset
            solutions for large projects.
          </p>
        </div>
      </div>

      <div
        class="grid grid-cols-12 col-span-12 text-left gap-y-8 sm:gap-8 md:mt-12"
      >
        <!-- Partners card -->
        <div
          v-for="PartnersCard in PartnersCards"
          :key="PartnersCard.id"
          class="grid col-span-12 sm:col-span-6 md:col-span-4"
        >
          <a
            :href="PartnersCard.ctaURL"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              class="content-center px-4 py-8 text-white duration-300 rounded-md sm:px-12 hover:-mt-1 hover:shadow-xl bg-blue-background hover:bg-blue justify-items-center"
            >
              <img
                class="mx-auto"
                :src="require('@/' + PartnersCard.logo)"
                width="600"
                height="200"
                alt="partner"
              />
            </div>
          </a>
        </div>
        <!-- End Partners card -->
      </div>
    </div>
  </div>
  <!-- End Partners -->

  <!-- Why Partner HYDN -->
  <div
    id="why-partner-hydn"
    class="container px-4 text-left text-white section block-top block-bottom bg-blue-background sm:text-center"
  >
    <h2 class="text-center">Why HYDN</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 gap-2 mx-auto sm:container">
      <!-- Why HYDN card -->
      <div
        v-for="WhyHYDNCard in WhyHYDN"
        :key="WhyHYDNCard.id"
        :class="{
          'sm:col-start-4 lg:col-start-0 col-span-12': WhyHYDNCard.id === 3,
        }"
        class="col-span-12 lg:col-span-4 sm:col-span-6"
      >
        <div class="flex justify-center text-left sm:text-center">
          <div class="block text-white md:p-8 md:mb-4 md:rounded-xl sm:grid">
            <img
              class="block float-left w-12 h-12 mb-32 mr-4 sm:mb-6 sm:float-none sm:mx-auto"
              src="@/assets/icons/tick.svg"
              alt="tick"
            />
            <h3 class="mb-2 text-3xl">
              {{ WhyHYDNCard.title }}
            </h3>
            <div class="flex-1">
              <p class="text-base">
                {{ WhyHYDNCard.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Why HYDN card -->
  </div>
  <!-- End Why HYDN -->

  <!-- Bottom CTA  -->
  <div class="py-4 bg-white container-full">
    <div class="px-8 py-12 mx-auto text-center">
      <span
        class="block pb-8 text-3xl font-extrabold text-blue-background sm:text-4xl md:pr-8 md:pb-0 md:inline-block sm:align-middle"
        >Looking to partner with HYDN?</span
      >
      <router-link
        to="/contact"
        class="block button hero sm:inline-block sm:align-middle"
        >Contact Us</router-link
      >
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import WhyPartnerCards from "../data/Partners/WhyPartnerCards.json";
import PartnersCards from "../data/Global/PartnersCards.json";
import WhyHYDN from "../data/Partners/WhyHYDN.json";
import TitleLine from "../components/utilities/TitleLine.vue";

import { Vue3Lottie } from "vue3-lottie";
import * as PartnersHero from "../assets/images/partners/lottie-partners-hero.json";

export default {
  components: {
    TitleLine,
    Vue3Lottie,
  },
  data() {
    return {
      WhyPartnerCards: WhyPartnerCards,
      PartnersCards: PartnersCards,
      WhyHYDN: WhyHYDN,

      PartnersHero,
      animationSpeed: 1,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  @apply w-full;
  img {
    @apply invert-[.30] duration-300 hover:invert-0 w-full max-h-[42px];
  }
}
</style>
