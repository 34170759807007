<template>
  <agile :options="myOptions">
    <!-- Hero / Smart Contract -->
    <div class="slide">
      <div class="container section container-full">
        <div
          class="grid content-start grid-cols-12 my-16 sm:gap-8 md:gap-24 md:mt-36"
        >
          <div class="col-span-12 text-left md:col-span-7">
            <h1>Smart Contract Audits</h1>
            <TitleLine position="left" colour="blue-light" />
            <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
              HYDN provides comprehensive smart contract audits from a team of
              experienced blockchain and cybersecurity experts
            </div>
            <div class="sm:flex">
              <router-link to="/security" class="button button__large"
                >Explore security services</router-link
              >
            </div>
          </div>

          <div class="hidden md:col-span-5 md:grid">
            <div>
              <Vue3Lottie :animationData="SecurityHero" :height="400" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Hero / Smart Contract -->
    <!-- Hero / HYDN Seekers -->

    <div class="slide">
      <div class="container section container-full">
        <div
          class="grid content-start grid-cols-12 my-16 sm:gap-8 md:gap-24 md:mt-36"
        >
          <div class="col-span-12 text-left md:col-span-7">
            <h1>HYDN Seekers</h1>
            <TitleLine position="left" colour="blue-light" />
            <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
              HYDN Seekers is on a mission to ensure that Web3 supports secure
              business operations for all. As part of this, the team
              investigates protocols in an attempt to find vulnerabilities
              before malicious actors do
            </div>
            <div class="sm:flex">
              <router-link to="/hydnseekers" class="button button__large"
                >Learn how it works</router-link
              >
            </div>
          </div>

          <div class="hidden md:col-span-5 md:grid">
            <div>
              <Vue3Lottie :animationData="HydnSeekersHero" :height="400" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Hero / HYDN Seekers -->

    <!-- Hero / Home -->
    <div class="slide">
      <div class="container section container-full">
        <div
          class="grid content-start grid-cols-12 my-16 sm:gap-8 md:gap-24 md:mt-36"
        >
          <div class="col-span-12 text-left md:col-span-7">
            <h1>Web3 Security and Consultancy Experts</h1>
            <TitleLine position="left" colour="blue-light" />
            <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
              HYDN helps to secure and accelerate ambitious Web3 companies with
              smart contract audits, tokenomics support, and business
              consultancy
            </div>
            <div class="sm:flex">
              <router-link to="/tokenomics" class="button button__large"
                >Discover more</router-link
              >
            </div>
          </div>

          <div class="hidden md:col-span-5 md:grid">
            <div>
              <Vue3Lottie :animationData="TokenomicsHero" :height="400" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Hero / Home -->
  </agile>

  <!-- The Team -->
  <div
    id="mission"
    class="mt-16 text-left container-full section block-bottom bg-blue-dark sm:text-center block-top"
  >
    <div
      class="grid max-w-screen-lg grid-cols-1 gap-8 mx-auto text-left team gap-y-0"
    >
      <!-- Team Card -->
      <div v-for="TeamCard in TeamCards" :key="TeamCard.id" class="grid">
        <div class="flex justify-center">
          <div
            class="grid w-full grid-cols-12 gap-4 px-6 rounded-xl md:gap-x-8 gap-y-0"
          >
            <div class="col-span-12 md:col-span-6">
              <h2 class="text-left">Mission</h2>
              <TitleLine position="left" colour="blue-light" />
            </div>
            <div class="col-span-12 md:col-span-6"></div>
            <div
              class="order-2 col-span-12 mt-8 md:mt-4 md:col-span-6 md:order-1"
            >
              <div class="pb-8 text-xl">
                “Our mission is to ensure the blockchain supports secure
                business operations for all. I have built the team at HYDN from
                the ground up to meet this very personal objective.”
              </div>

              <div class="grid grid-cols-12">
                <h4 class="col-span-12 mb-4">
                  {{ TeamCard.name }}
                </h4>

                <div
                  v-html="TeamCard.bio"
                  class="col-span-12 md:col-span-12"
                ></div>
                <div
                  v-html="TeamCard.quote"
                  class="col-span-12 md:col-span-12"
                ></div>
                <a
                  v-if="TeamCard.twitter"
                  :href="TeamCard.twitter"
                  target="_blank"
                  type="button"
                  class="col-span-2 sm:col-span-1 icon"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/icons/twitter.svg"
                    alt="twitter"
                    width="48"
                    height="48"
                    loading="lazy"
                  />
                </a>
                <a
                  v-if="TeamCard.telegram"
                  :href="TeamCard.telegram"
                  target="_blank"
                  type="button"
                  class="col-span-2 sm:col-span-1 icon"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/icons/telegram.svg"
                    alt="telegram"
                    width="48"
                    height="48"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
            <div
              v-if="TeamCard.image"
              class="order-1 col-span-12 md:col-span-6 md:order-2"
            >
              <img
                class="shadow-xl rounded-2xl"
                :src="require('@/' + TeamCard.image)"
                alt="team"
                width="1477"
                height="1588"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Team Card -->
  </div>
  <!-- End The Team -->

  <!-- Smart Contract Audits -->
  <div id="what-we-do" class="container-full block-top block-bottom">
    <div class="grid max-w-screen-lg grid-cols-12 gap-0 mx-auto section">
      <div class="col-span-12 px-24 sm:col-span-6">
        <Vue3Lottie :animationData="WhatWeDo" />
      </div>
      <div
        class="flex items-center justify-center col-span-12 px-6 text-left sm:col-span-6"
      >
        <div>
          <h3 class="text-left md:text-left">Smart Contract Audits</h3>
          <p class="text-base">
            HYDN provides comprehensive smart contract audits and blockchain
            security services from a team of experienced blockchain and
            cybersecurity professionals.
          </p>
          <ul>
            <li>Smart Contract Audits</li>
            <li>KYC Verification</li>
            <li>DeFi Protocol Audits</li>
            <li>Wallet Security Audits</li>
          </ul>
          <router-link to="/security" class="mt-8 button button__large"
            >View availability</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!-- End Smart Contract Audits -->

  <!-- HYDNSeekers -->
  <div
    id="hydnseekers"
    class="px-8 container-full block-top block-bottom bg-blue-dark"
  >
    <div class="grid max-w-screen-lg grid-cols-12 gap-0 mx-auto section">
      <div
        class="flex items-center justify-center order-2 col-span-12 sm:col-span-6 md:order-1"
      >
        <div>
          <h3 class="text-left md:text-left">HYDN Seekers</h3>
          <p class="text-base">
            HYDN Seekers test protocols for vulnerabilities 24 hours a day, 7
            days a week, 365 days a year.
          </p>
          <p>
            HYDN belives that in carrying out this service we are doing our part
            to help make the blockchain a safe space for both users and protocol
            owners, as well as ensuring that the HYDN team stay on top of the
            latest security trends.
          </p>
          <router-link to="/hydnseekers" class="mt-8 button button__large"
            >Why do we do it?</router-link
          >
        </div>
      </div>
      <div class="order-1 col-span-12 px-24 sm:col-span-6 md:order-2">
        <Vue3Lottie :animationData="HydnSeekers" />
      </div>
    </div>
  </div>
  <!-- End HYDNSeekers -->

  <!-- Tokenomics -->
  <div id="tokenomics" class="container-full block-top block-bottom">
    <div class="grid max-w-screen-lg grid-cols-12 gap-0 mx-auto section">
      <div class="col-span-12 px-24 md:col-span-6">
        <Vue3Lottie :animationData="Tokenomics" />
      </div>
      <div
        class="flex items-center justify-center col-span-12 px-6 text-left md:col-span-6"
      >
        <div>
          <h3 class="text-left md:text-left">Tokenomics & Consultancy</h3>
          <p class="text-base">
            HYDN provides advisory services for digital asset companies,
            including tokenomics consultancy, go-to-market strategy, and crypto
            infrastructure consultancy.
          </p>
          <p>
            The HYDN team have worked on a range of blockchain protocols
            including play-to-earn games, decentralized exhcanges, and more
          </p>
          <router-link to="/tokenomics" class="mt-8 button button__large"
            >Discover our team
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- End Tokenomics -->

  <!-- Partners -->
  <div
    id="partners"
    class="text-left bg-white text-grey-dark section container-full block-top block-bottom sm:text-center"
  >
    <h2 class="text-center">Partners</h2>
    <TitleLine position="center" colour="blue-light" />
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 px-6 mt-16 text-left sm:container sm:gap-8 sm:mx-auto sm:text-center"
    >
      <!-- Partners card -->
      <div
        v-for="PartnersCard in PartnersCards"
        :key="PartnersCard.id"
        class="grid col-span-12 md:col-span-6 lg:col-span-4"
      >
        <div class="grid grid-cols-1">
          <a
            :href="PartnersCard.ctaURL"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              class="content-center px-6 py-8 text-white duration-300 rounded-md sm:px-12 hover:-mt-1 hover:shadow-xl bg-blue-background hover:bg-blue justify-items-center"
            >
              <img
                class="mx-auto"
                :src="require('@/' + PartnersCard.logo)"
                width="600"
                height="200"
                alt="partner"
                loading="lazy"
              />
            </div>
          </a>
        </div>
      </div>
      <!-- End Partners card -->
    </div>
  </div>
  <!-- End Partners -->
</template>

<script>
import TeamCards from "../data/Home/TeamCards.json";
import ResearchCards from "../data/Home/ResearchCards.json";
import PartnersCards from "../data/Global/PartnersCards.json";

import TitleLine from "../components/utilities/TitleLine.vue";
import { VueAgile } from "vue-agile";
import { Vue3Lottie } from "vue3-lottie";
import * as HydnSeekers from "../assets/images/home/lottie-hydnseekers.json";
import * as Tokenomics from "../assets/images/home/lottie-tokenomics.json";
import * as WhatWeDo from "../assets/images/home/lottie-whatwedo.json";
import * as SecurityHero from "../assets/images/security/lottie-security-hero.json";
import * as TokenomicsHero from "../assets/images/tokenomics/lottie-tokenomics-hero.json";
import * as HydnSeekersHero from "../assets/images/hydnseekers/lottie-hydnseekers-hero.json";
export default {
  components: {
    TitleLine,
    agile: VueAgile,
    Vue3Lottie,
  },

  data() {
    return {
      TeamCards: TeamCards,
      ResearchCards: ResearchCards,
      PartnersCards: PartnersCards,

      HydnSeekers,
      Tokenomics,
      WhatWeDo,
      SecurityHero,
      TokenomicsHero,
      HydnSeekersHero,
      animationSpeed: 1,

      myOptions: {
        autoplaySpeed: 10000,
        slidesToShow: 1,
        mobileFirst: true,
        pauseOnDotsHover: true,
        timing: "ease-out",
        speed: 100,
        changeDelay: 100,
        responsive: [
          {
            breakpoint: 300,
            settings: {
              arrows: false,
              navButtons: false,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              arrows: true,
              navButtons: true,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.icon {
  @apply w-full sm:pr-2;
  img {
    @apply invert-[1] duration-300 hover:opacity-[.8] w-full max-h-[35px];
  }
}
</style>
