<template>
  <!-- Hero / Home -->
  <div id="about" class="container section container-full">
    <div
      class="grid max-w-screen-md grid-cols-12 mx-auto my-16 sm:gap-8 md:mt-36 md:mb-24"
    >
      <div class="col-span-12 text-center md:col-span-12">
        <h1>HYDN is SECURING <br />Web3 for EVERYONE</h1>
        <TitleLine position="center" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          Founded by cybersecurity expert, Warren Mercer, HYDN is on a mission
          to ensure the blockchain supports secure business operations for all.
          With over 40 years worth of combined experience in cybersecurity,
          blockchain, and consulting, HYDN brings tier one business processess
          to the Web3 world.
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero / Home -->

  <!-- About Us -->
  <div
    id="about-us"
    class="mx-auto text-left bg-white text-grey-dark container-full block-top block-bottom"
  >
    <div class="grid max-w-screen-lg grid-cols-12 mx-auto">
      <div class="col-span-12 px-6 sm:col-span-6">
        <h2>About Us</h2>
        <TitleLine position="left" colour="blue-light" />

        <p>
          HYDN became a public facing company in 2022 after operating as a Web3
          security consultancy behind closed doors in 2021.
        </p>
        <p>
          HYDN started life as a Web3 cybersecurity firm founded by Warren
          Mercer. Warren built the team from the ground up, drawing upon his
          industry contacts to hire the best and brightest talent in the sector,
          with the aim of bringing top tier cybersecurity expertise to the Web3
          world.
        </p>
        <p>
          Initially focusing on smart contract audits and Web3 security, we
          quickly found that many projects required further business and
          marketing support beyond security audits.
        </p>
        <p>
          To provide a solution to this, we expanded the team, hiring
          experienced go-to-market consultants, tokenomics experts, and branding
          and marketing partners to ensure that we can provide full 360 support
          to our clients across a range of vectors.
        </p>
        <p>
          HYDN has worked on a number of projects including play-to-earn games,
          NFT releases, DeFi protocols, and more over the past year and is now
          expanding the business offering to become public facing.
        </p>
      </div>
      <div class="flex items-center col-span-12 px-6 sm:col-span-6">
        <img
          src="@/assets/images/about/about-us.webp"
          alt="About HYDN"
          width="877"
          height="1011"
          class="mx-auto"
        />
      </div>
    </div>
  </div>
  <!-- End About Us-->

  <!-- The Team -->
  <div
    id="mission"
    class="container text-left section block-bottom bg-blue-background sm:text-center"
  >
    <div class="grid grid-cols-1 gap-8 mt-16 text-left team gap-y-0">
      <!-- Team Card -->
      <div v-for="TeamCard in TeamCards" :key="TeamCard.id" class="grid">
        <div class="flex justify-center">
          <div
            class="grid w-full grid-cols-12 gap-4 rounded-xl md:gap-x-8 gap-y-0"
          >
            <div
              class="order-2 col-span-12 mt-8 md:mt-4 md:col-span-6 md:order-1"
            >
              <div class="grid grid-cols-12">
                <div class="col-span-12">
                  <h2 class="text-left">{{ TeamCard.name }}</h2>
                  <TitleLine position="left" colour="blue-light" />
                </div>
                <div
                  v-html="TeamCard.bio"
                  class="col-span-12 md:col-span-12"
                ></div>
                <div
                  v-html="TeamCard.quote"
                  class="col-span-12 md:col-span-12"
                ></div>
                <a
                  v-if="TeamCard.twitter"
                  :href="TeamCard.twitter"
                  target="_blank"
                  type="button"
                  class="col-span-2 sm:col-span-1 icon"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/icons/twitter.svg"
                    alt="twitter"
                    width="48"
                    height="48"
                  />
                </a>
                <a
                  v-if="TeamCard.telegram"
                  :href="TeamCard.telegram"
                  target="_blank"
                  type="button"
                  class="col-span-2 sm:col-span-1 icon"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/icons/telegram.svg"
                    alt="telegram"
                    width="48"
                    height="48"
                  />
                </a>
              </div>
            </div>
            <div
              v-if="TeamCard.image"
              class="order-1 col-span-12 md:col-span-6 md:order-2"
            >
              <img
                class="shadow-xl rounded-2xl"
                :src="require('@/' + TeamCard.image)"
                width="500"
                height="500"
                alt="team"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Team Card -->
  </div>
  <!-- End The Team -->

  <!-- Global Team -->
  <div
    id="global-team"
    class="mx-auto text-left bg-white text-grey-dark section container-full block-top block-bottom"
  >
    <div class="grid max-w-screen-lg grid-cols-12 mx-auto">
      <div class="col-span-12 px-6 md:col-span-6">
        <h2>Global Team</h2>
        <TitleLine position="left" colour="blue-light" />
        <p>
          The HYDN delivery team has extensive experience in the cybersecurity
          and blockchain space, developing security systems for multinationals
          and tech startups. The team has several years of experience in
          building as well as securing DeFi protocols with the value of over
          $100M.
        </p>
        <p>
          With over 40 years of combined experience in financial services and
          business modelling, including having held senior roles at the likes of
          Citi, Royal Park Partners, Bosch, FirstGroup, Arriva, and Capital
          Group, as well as boasting economic graduates from Oxford and
          Cambridge Universities, the HYDN team are on hand to provide expert
          consultancy on a wide range of topics.
        </p>
        <p>
          The delivery team is a truly global team with members spread
          throughout the UK, Denmark, France, Portugal, and Spain to cover a
          range of languages, time zones, and ways of working to provide the
          best possible service to our clients.
        </p>
      </div>
      <div class="flex items-center col-span-12 px-8 mt-12 md:col-span-6">
        <img
          src="@/assets/images/about/team-logos.webp"
          alt="About HYDN"
          width="2094"
          height="1979"
          class="mx-auto"
        />
      </div>
    </div>
  </div>
  <!-- End Global Team -->

  <!-- Bottom CTA  -->
  <div
    class="px-8 py-8 mx-auto sm:py-16 md: sm:px-16 md:px-24 bg-blue container-full"
  >
    <div class="grid max-w-screen-md grid-cols-12 mx-auto text-left">
      <div class="col-span-12 sm:col-span-8">
        <h3 class="block text-3xl font-extrabold text-white sm:text-4xl">
          Want to join the HYDN team?
        </h3>
      </div>
      <div class="col-span-12 sm:col-span-4"></div>
      <div class="col-span-12 sm:col-span-8">
        <span class="block text-lg font-bold text-white sm:mr-8">
          If you're looking to join an ambitious, hungry team that is dedicated
          to making the Web3 world a more secure place then check out our job
          openings today
        </span>
      </div>
      <div class="col-span-12 mt-4 sm:col-span-4 sm:mt-0">
        <router-link to="/careers" class="block button"
          >Careers Page</router-link
        >
      </div>
    </div>
  </div>
  <!-- End Bottom CTA  -->

  <!-- In the News -->
  <div
    id="in-the-news"
    class="container hidden text-left bg-blue-background section block-top block-bottom sm:text-center"
  >
    <h2>In The News</h2>
    <TitleLine position="center" colour="blue-light" />
    <div
      class="grid grid-cols-12 mt-16 text-center sm:gap-8 gap-y-8 text-grey-dark team"
    >
      <!-- Blog Card -->
      <div
        v-for="News in News.slice().reverse()"
        :key="News.id"
        class="col-span-12 px-6 py-12 bg-white md:col-span-4 md:p-8 rounded-xl md:gap-8"
      >
        <div v-if="News.image" class="md:block">
          <a
            v-if="News.url"
            :href="News.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="p-2 shadow-xl rounded-2xl"
              :src="require('@/' + News.image)"
              alt="News"
              width="500"
              height="500"
          /></a>
        </div>

        <div class="col-span-4 md:mt-0">
          <div class="grid grid-cols-4">
            <h5 class="col-span-4 mt-4 mb-2 font-bold">
              <a
                v-if="News.url"
                :href="News.url"
                target="_blank"
                rel="noopener noreferrer"
                >{{ News.title }}</a
              >
            </h5>
            <div
              v-html="News.date"
              class="col-span-12 mb-4 text-sm md:col-span-4"
            ></div>
            <div v-html="News.excerpt" class="col-span-12 md:col-span-4"></div>
            <div class="col-span-12 mt-4 socials md:col-span-4">
              <a
                v-if="News.url"
                :href="News.url"
                target="_blank"
                class="button button__large"
                rel="noopener noreferrer"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Blog Card -->
  </div>
  <!-- End In the News -->
</template>

<script>
import TeamCards from "../data/About/TeamCards.json";
import ResearchCards from "../data/About/ResearchCards.json";
import News from "../data/About/News.json";

import TitleLine from "../components/utilities/TitleLine.vue";
export default {
  components: {
    TitleLine,
  },

  data() {
    return {
      News: News,
      TeamCards: TeamCards,
      ResearchCards: ResearchCards,

      myOptions: {
        autoplay: false,
        autoplaySpeed: 10000,
        dots: true,
        infinite: true,
        navButtons: false,
        slidesToShow: 1,
        mobileFirst: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        timing: "ease-in-out",
        speed: 600,
        fade: true,
        responsive: [
          {
            breakpoint: 300,
            settings: {
              dots: true,
              arrows: false,
              navButtons: false,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              dots: true,
              arrows: true,
              navButtons: true,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.icon {
  @apply w-full sm:pr-2;
  img {
    @apply invert-[1] duration-300 hover:opacity-[.8] w-full max-h-[35px];
  }
}
</style>
