<template>
  <!-- Section 1 -->
  <section class="container my-20">
    <div class="mx-auto">
      <div
        class="relative flex flex-col gap-8 lg:gap-4 h-full lg:flex-row align-baseline"
      >
        <div class="bg-cover lg:w-6/12 xl:w-5/12 lg:flex">
          <div
            class="relative sticky top-1/3 flex flex-col items-center justify-center w-full min-h-full md:min-h-fit md:h-2/5 md:my-20 lg:pr-16 lg:mt-36"
          >
            <div class="flex flex-col items-start tracking-tight lg:max-w-3xl">
              <div class="relative">
                <h2 class="mb-0 text-5xl font-extrabold text-white xl:text-6xl">
                  Get in Touch
                </h2>
                <TitleLine position="left" colour="blue-light" />
              </div>
              <p class="text-lg">
                Rush audits available in August<br />
                Limited availability in September
              </p>
              <p class="text-lg">
                <a
                  :href="data.telegramURL"
                  rel="noopener noreferrer"
                  target="_blank"
                  >Message us on Telegram</a
                >
              </p>
              <p>
                <a
                  class="text-lg"
                  :href="'mailto:' + data.companyEmail"
                  rel="noopener noreferrer"
                  >Email at {{ data.companyEmail }}</a
                >
              </p>
              <TitleLine position="left" colour="blue-light" />
              <p class="text-base">
                We work with a wide variety of blockchain teams, from start-ups
                to multinationals. Whether you’re looking for a full smart
                contract security audit, tokenomics consultancy, go to market
                strategy, or one of our other services, HYDN is here to help.
              </p>
            </div>
          </div>
        </div>
        <div
          class="w-full bg-white text-grey-dark p-8 lg:mt-24 md:my-0 lg:w-6/12 xl:w-7/12 rounded-md"
        >
          <Form />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleLine from "../components/utilities/TitleLine.vue";
import Form from "../components/Contact/Forms/Form.vue";
import * as companyData from "@/globals.js";

export default {
  components: {
    TitleLine,
    Form,
  },
  data() {
    return {
      query: this.$route.query.q,
      data: companyData,
    };
  },
};
</script>

<style lang="scss">
.form {
  &__input,
  &__select {
    @apply block w-full px-4 py-2 mt-2 text-xl placeholder-grey-light border border-grey-light rounded-lg duration-300 focus:outline-blue-light focus:ring-4 focus:ring-blue-light focus:ring-opacity-40;
  }
  &__textarea {
    @apply block w-full h-36 px-4 py-2 mt-2 text-xl placeholder-grey-light border border-grey-light rounded-lg duration-300 focus:outline-blue-light focus:ring-4 focus:ring-blue-light focus:ring-opacity-40;
  }
}
.required {
  label {
    &:first-child {
      &:after {
        @apply text-red ml-1;
        content: "*";
      }
    }
  }
}
</style>
