<template>
  <!-- Completed -->
  <div
    id="completed-audits"
    class="text-center text-white container-full section block-top block-bottom"
  >
    <div
      class="grid grid-cols-12 mx-auto text-left max-w-screen md:max-w-screen-xl"
    >
      <div class="col-span-12 md:mt-0">
        <h2 class="mx-4 text-center">Completed Audits</h2>
        <TitleLine position="center" colour="blue-light" />
        <table class="table-fixed">
          <thead>
            <tr>
              <th>Date</th>
              <th>Network</th>
              <th>Protocol</th>
              <th>Link to Audit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="content in Disclosed.slice().reverse()"
              :key="content.id"
            >
              <td>{{ content.date }}</td>
              <td>{{ content.network }}</td>
              <td>{{ content.protocol }}</td>
              <td v-for="link in content.links" :key="link">
                <span v-if="link.github" class="icon">
                  <a
                    :href="link.github"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="@/assets/icons/github.svg" alt="github" />
                  </a>
                </span>
                <span v-if="link.medium" class="icon">
                  <a
                    :href="link.medium"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="@/assets/icons/medium.svg" alt="medium" />
                  </a>
                </span>
                <span v-if="link.PDF" class="icon">
                  <a :href="link.PDF" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/icons/pdf.svg" alt="pdf" />
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- End Completed -->

  <!-- Bottom CTA  -->
  <div class="p-12 mx-auto md:px-24 bg-blue container-full">
    <div class="grid max-w-screen-md grid-cols-12 mx-auto text-left">
      <div class="col-span-12 sm:col-span-8">
        <h3 class="block text-3xl font-extrabold text-white sm:text-4xl">
          Smart Contract Audit Experts
        </h3>
      </div>
      <div class="col-span-12 sm:col-span-4"></div>
      <div class="col-span-12 sm:col-span-8">
        <span class="block text-lg font-bold text-white sm:mr-8">
          HYDN provides comprehensive Smart Contract Audits from a team of
          experienced blockchain and cybersecurity experts
        </span>
      </div>
      <div class="col-span-12 mt-4 sm:col-span-4 sm:mt-0">
        <router-link to="/contact?q=smart-contract-audit" class="block button"
          >Request a Quote</router-link
        >
      </div>
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import Disclosed from "../data/CompletedAudits/Disclosed.json";
import TitleLine from "../components/utilities/TitleLine.vue";

export default {
  components: {
    TitleLine,
  },
  data() {
    return {
      Disclosed: Disclosed,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  @apply w-full;
  img {
    @apply invert-[.30] duration-300 hover:invert-0 w-full max-h-[42px];
  }
}

table {
  @apply w-full text-sm md:text-base;
  border-collapse: collapse;
  border-spacing: 0;
  th {
    @apply text-left text-white pl-2 md:px-4 py-4 bg-blue border-b-2 border-grey-light;
  }
  tr {
    @apply even:bg-blue/40;
  }
  td {
    @apply pl-2 md:px-4 md:py-4 py-2 border-b-2 border-grey-light;
  }
  .icon {
    @apply inline-block max-w-[20px] md:max-w-[30px] m-0.5 md:m-1 invert-[.60] duration-300 hover:invert-[1];
  }
}
</style>
