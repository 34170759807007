<template>
  <!-- Hero -->
  <div id="hydnseekers" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:my-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>Have you been contacted by HYDN Seekers?</h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mb-12 sm:mb-4 md:mt-16 text-md md:text-lg lg:text-xl">
          <div
            class="grid grid-cols-12 pb-4 mt-4 md:mt-2"
            v-for="content in HowItWorks"
            :key="content.id"
          >
            <h3 class="col-span-1 mb-0 text-5xl">{{ content.id }}</h3>
            <div
              v-html="content.content"
              class="col-span-11 text-lg md:col-span-11"
            ></div>
          </div>
        </div>
        <div class="ml-8 sm:flex md:ml-16">
          <router-link to="/contact?q=hydn-seekers" class="button hero"
            >Get Help</router-link
          >
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex items-center">
        <div>
          <Vue3Lottie :animationData="HydnSeekersHero" />
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- Unclaimed Findings -->
  <div
    id="unclaimed-vulnerability"
    class="text-center text-white bg-blue-dark block-top block-bottom container-full section"
  >
    <div
      class="grid grid-cols-12 mx-auto text-left max-w-screen md:max-w-screen-lg"
    >
      <div class="col-span-12 mt-8">
        <h2 class="mx-4 text-center">Unclaimed Vulnerability Findings</h2>
        <TitleLine position="center" colour="blue-light" />
        <div
          class="max-w-screen-md px-6 mx-auto my-8 text-center text-md md:text-lg lg:text-xl"
        >
          HYDN lists all Unclaimed Vulnerability Findings on this page but with
          no verifiable information. All information is anonymous.
        </div>
        <div class="sm:px-6">
          <table class="table mt-12">
            <thead>
              <tr>
                <th>Date</th>
                <th>Network</th>
                <th>Protocol</th>
                <th>Issue</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="content in Unclaimed.slice().reverse()"
                :key="content.id"
              >
                <td>{{ content.date }}</td>
                <td>{{ content.network }}</td>
                <td>{{ content.protocol }}</td>
                <td>{{ content.issue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- End Unclaimed Findings -->

  <!-- Banner -->

  <div
    class="grid items-center grid-cols-12 py-12 border-2 border-x-0 border-y-white bg-blue"
  >
    <img
      src="@/assets/images/security/audit-lock.png"
      alt="Vulnerability Verification"
      class="col-span-1 col-start-2 mx-auto md:col-start-3"
      width="149"
      height="200"
    />
    <h4
      class="col-span-9 ml-12 text-3xl text-left md:col-span-7 md:text-4xl lg:text-5xl"
    >
      HYDN follows responsible disclosure where possible and will not publicly
      reveal details of potential exploits until they have been remediated
    </h4>
  </div>
  <!-- End Banner -->

  <!-- What to expect -->
  <div
    id="what-to-expect"
    class="text-center text-white bg-blue-background container-full section block-top block-bottom"
  >
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto"
    >
      <div class="col-span-12 mt-8 md:mt-0 md:col-span-6">
        <h2 class="text-center md:text-left">{{ WhatToExpect.title }}</h2>
        <TitleLine
          position="left"
          colour="blue-light"
          class="hidden md:block"
        />
        <TitleLine
          position="center"
          colour="blue-light"
          class="block md:hidden"
        />
        <div class="grid grid-cols-12">
          <div
            v-html="WhatToExpect.bullets"
            class="col-span-12 pb-8 text-2xl"
          ></div>
          <div
            v-html="WhatToExpect.content"
            class="col-span-12 md:col-span-12"
          ></div>
        </div>
      </div>
      <div
        v-if="WhatToExpect.image"
        class="hidden col-span-12 pr-8 mt-4 md:col-span-5 md:col-start-8 lg:col-span-4 lg:col-start-8 md:block"
      >
        <img :src="require('@/' + WhatToExpect.image)" alt="expectation" />
      </div>
    </div>
  </div>
  <!-- End What to expect -->

  <!-- Why HYDN -->
  <div
    id="why-hydn"
    class="text-center text-white bg-blue-background container-full section block-top block-bottom bg-[url('@/assets/images/hydnseekers/why-hydn-background.png')] bg-cover bg-blend-overlay border-t-2"
  >
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 px-6 mx-4 text-left sm:gap-8 sm:mx-auto"
    >
      <div class="col-span-12 mt-8 md:col-span-8 md:col-start-3 md:mt-0">
        <h2 class="text-center">{{ WhyHydn.title }}</h2>
        <TitleLine position="center" colour="blue-light" />
        <div class="grid grid-cols-12">
          <div
            v-html="WhyHydn.content"
            class="col-span-12 md:col-span-12"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Why HYDN -->

  <!-- Bottom CTA  -->
  <div class="py-4 bg-white container-full">
    <div class="px-8 py-12 mx-auto text-center">
      <span
        class="block pb-8 text-3xl font-extrabold text-blue-background sm:text-4xl md:pr-8 md:pb-0 md:inline-block sm:align-middle"
        >Need help from HYDN Seekers?</span
      >
      <router-link
        to="/contact?q=hydnseekers"
        class="block button hero sm:inline-block sm:align-middle"
        >Contact Us</router-link
      >
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import HowItWorks from "../data/HydnSeekers/HowItWorks.json";
import WhatToExpect from "../data/HydnSeekers/WhatToExpect.json";
import Unclaimed from "../data/HydnSeekers/Unclaimed.json";
import WhyHydn from "../data/HydnSeekers/WhyHydn.json";
import TitleLine from "../components/utilities/TitleLine.vue";

import { Vue3Lottie } from "vue3-lottie";
import * as HydnSeekersHero from "../assets/images/hydnseekers/lottie-hydnseekers-hero.json";

export default {
  components: {
    TitleLine,
    Vue3Lottie,
  },
  data() {
    return {
      HowItWorks: HowItWorks,
      Unclaimed: Unclaimed,
      WhatToExpect: WhatToExpect,
      WhyHydn: WhyHydn,

      HydnSeekersHero,
      animationSpeed: 1,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  @apply w-full;
  img {
    @apply invert-[.30] duration-300 hover:invert-0 w-full max-h-[42px];
  }
}

table {
  @apply w-full text-sm md:text-base;
  border-collapse: collapse;
  border-spacing: 0;
  th {
    @apply text-left text-white pl-2 md:px-4 py-4 bg-blue border-b-2 border-grey-light;
  }
  tr {
    @apply even:bg-blue/40;
  }
  td {
    @apply pl-2 md:px-4 md:py-4 py-2 border-b-2 border-grey-light;
  }
  .icon {
    @apply inline-block max-w-[20px] md:max-w-[30px] m-0.5 md:m-1 invert-[.60] duration-300 hover:invert-[1];
  }
}
</style>
