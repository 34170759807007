<template>
  <div
    class="w-full bg-white text-grey-dark md:my-16 sm:my-0"
    v-if="isSubmitted"
  >
    <div
      class="flex flex-col items-start justify-start w-full p-10 mx-auto text-center lg:p-16 xl:p-24"
    >
      <h1 class="w-full text-grey-dark">Form Submitted Successfully</h1>
      <h5 class="w-full mt-4 text-center">
        We will be in touch soon. Thank you.
      </h5>
    </div>
  </div>
  <form
    @submit="onSubmit"
    class="relative w-full space-y-8"
    v-if="!isSubmitted"
    id="container"
  >
    <h5 class="mt-4 text-lg text-grey-dark">
      Please fill out the form to share your file(s) with the Hydn team. Thank
      you.
    </h5>
    <div class="relative required">
      <label class="font-medium text-grey-dark">Your Email</label>
      <input
        required
        name="email"
        v-model="email"
        type="email"
        class="form__input form-input"
        placeholder="Enter Your Email"
      />
    </div>
    <div class="relative required">
      <label class="font-medium text-grey-dark">File(s) description</label>
      <input
        required
        name="description"
        v-model="description"
        type="text"
        class="form__input form-input"
        placeholder="Enter file description"
      />
    </div>
    <div class="relative required flex-col">
      <label class="flex font-medium text-grey-dark">Select your file(s)</label>
      <label
        class="flex justify-center font-medium text-grey-light hover:text-blue-dark w-1/2 sm:w-1/4 border mt-2 p-2 rounded-md cursor-pointer border-grey-light"
        :class="this.files.length > 0 ? 'border-blue-light' : ''"
        for="upload-file"
      >
        <img
          src="@/assets/icons/upload.svg"
          alt="upload"
          width="16"
          height="16"
          class="inline mr-2"
        />Upload</label
      >
      <input
        required
        name="files"
        id="upload-file"
        v-on:change="handleFileUpload"
        type="file"
        class="hidden"
        multiple
      />
      <ul class="text-grey-light text-small py-1">
        <li v-for="file in Array.from(files)" :key="file.name">
          {{ file.name }}
        </li>
      </ul>
    </div>
    <div class="relative">
      <button
        :disabled="isSubmitting"
        type="submit"
        class="w-full button button__large"
      >
        <img
          v-if="isSubmitting"
          src="@/assets/icons/spinner.svg"
          alt="spinner"
          width="16"
          height="16"
          class="animate-spin inline mr-2"
        />
        Submit
      </button>
    </div>
  </form>
</template>

<script>
const url =
  "https://script.google.com/macros/s/AKfycbyaUm8iz61iV6R5U2iw8pLNWz9fXWGz_F0HCPc4ktU2hlI1Ih68cD2vLQTPueUoYLNjcw/exec";
export default {
  components: {},
  methods: {
    handleFileUpload(event) {
      this.files = event.target.files;
    },
    onSubmit(event) {
      event.preventDefault();
      this.isSubmitting = true;
      Promise.all(
        Array.from(this.files).map((file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("loadend", (e) => {
              if (e.target.error) {
                console.error("Error to read file", e.target.error);
              } else {
                resolve(e.target.result);
              }
            });
            reader.readAsDataURL(file);
          });
        })
      ).then((filesEncoded) => {
        const formData = new FormData();
        formData.append("email", this.email);
        formData.append("description", this.description);
        filesEncoded.forEach((fileEncoded, i) => {
          formData.append(`file_${i + 1}`, filesEncoded[i]);
          formData.append(`fileName_${i + 1}`, this.files[i].name);
        });
        fetch(url, {
          mode: "cors",
          redirect: "follow",
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then(() => {
            // console.info("Upload response", res);
            this.isSubmitting = false;
            this.isSubmitted = true;
            const container = document.querySelector("#container");
            container.scrollIntoView({ behavior: "smooth" });
          })
          .catch((error) => {
            console.error("Failed to send file", error);
            this.isSubmitting = false;
          });
      });
    },
  },
  data() {
    return {
      isSubmitting: false,
      isSubmitted: false,
      email: "",
      description: "",
      files: [],
    };
  },
};
</script>
