<template>
  <!-- Hero -->
  <div id="security" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:my-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>Smart Contract Audit Experts</h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          HYDN provides comprehensive smart contract audits from a team of
          experienced blockchain and cybersecurity professionals
        </div>
        <div class="sm:flex">
          <router-link to="/contact?q=smart-contract-audit" class="button hero"
            >Request a Quote</router-link
          >
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex items-center">
        <Vue3Lottie :animationData="SecurityHero" />
        <div></div>
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- Costs -->
  <div
    id="costs"
    class="text-center bg-white text-grey-dark section container-full block-top"
  >
    <!-- Availiability loop 1 -->
    <div
      class="grid max-w-screen-lg grid-cols-12 mx-8 text-left sm:divide-x-2 sm:container sm:mx-auto"
    >
      <div class="col-span-12 sm:col-span-6 lg:pl-24">
        <h2>Availability</h2>
        <TitleLine position="left" colour="blue-light" />
        <div v-if="availabilities.length === 0">Checking availabilities...</div>
        <div
          v-for="availability in availabilities"
          :key="availability.date"
          class="block w-full mb-8"
        >
          <h6 class="pb-4 text-xl font-extrabold">
            {{
              new Date(availability.date).toLocaleString("default", {
                month: "long",
              })
            }}
          </h6>
          <ul class="list-disc">
            <li v-if="availability.in_progress">
              <b>{{ availability.in_progress }}</b> audits in progress
            </li>
            <li v-if="availability.small_available">
              <b>{{ availability.small_available }}</b> small audit slot
              available
            </li>
            <li v-if="availability.medium_available">
              <b>{{ availability.medium_available }}</b> medium audit slot
              available
            </li>
            <li v-if="availability.large_available">
              <b>{{ availability.large_available }}</b> large audit slot
              available
            </li>
          </ul>
        </div>
      </div>

      <div class="col-span-12 sm:pl-12 sm:col-span-6">
        <div v-for="Cost in Costs" :key="Cost.id" class="block w-full">
          <h2>
            {{ Cost.title }}
          </h2>
          <TitleLine position="left" colour="blue-light" />
          <p class="list-disc" v-html="Cost.cost"></p>
        </div>
      </div>
    </div>
  </div>
  <!-- End Costs -->

  <!-- Services -->
  <div
    id="detailed-audits"
    class="text-center bg-white text-grey-dark section container-full block-top block-bottom"
  >
    <h2>Detailed Smart Contract Security Audits</h2>
    <TitleLine position="center" colour="blue-light" />
    <!-- service card loop 1 -->
    <div
      class="grid max-w-screen-xl grid-cols-6 gap-2 mx-4 mt-16 text-center sm:container sm:gap-8 sm:mx-auto"
    >
      <div
        v-for="DetailedAudit in DetailedAudits"
        :key="DetailedAudit.id"
        class="flex col-span-6 md:col-span-2"
      >
        <div
          class="flex flex-col w-full px-8 py-16 text-white rounded-none shadow-2xl sm:px-12 md:rounded-md bg-blue-background"
        >
          <h5 class="my-auto mb-2 font-extrabold">
            {{ DetailedAudit.title }}
          </h5>
        </div>
      </div>
    </div>
    <!-- End service card loop 2 -->
  </div>
  <!-- End Services -->

  <!-- Audit Process -->
  <div
    id="audit-process"
    class="text-center text-white container-full section block-top block-bottom bg-blue-background sm:text-center"
  >
    <h2 class="text-center">Our 6 Step Audit Process</h2>
    <TitleLine position="center" colour="blue-light" />

    <img
      src="@/assets/images/security/audit-process-mobile.svg"
      alt="Smart Contract Audit"
      class="col-span-1 col-start-3 px-6 mx-auto md:hidden"
      height="817"
      width="284"
    />
    <img
      src="@/assets/images/security/audit-process-desktop.svg"
      height="185"
      width="1112"
      alt="Smart Contract Audit"
      class="hidden col-span-1 col-start-3 px-8 mx-auto md:block block-top block-bottom"
    />
    <!-- Banner -->

    <div
      class="grid items-center grid-cols-12 py-8 mt-12 border-2 md:py-12 sm:mt-16 border-x-0 border-y-white bg-blue"
    >
      <img
        src="@/assets/images/security/audit-lock.webp"
        width="149"
        height="200"
        alt="Smart Contract Audit"
        class="col-span-2 col-start-2 py-4 mx-auto md:col-start-3 md:col-span-1"
      />
      <h4 class="col-span-7 ml-12 text-2xl text-left sm:text-4xl lg:text-5xl">
        Over $8 billion in digital assets has been lost due to preventable
        vulnerabilities and exploits
      </h4>
    </div>
    <!-- End Banner -->
  </div>
  <!-- End Audit Process -->

  <!-- Audit Report -->
  <div
    id="audit-report"
    class="mx-auto text-left bg-white text-grey-dark section container-full block-top"
  >
    <div class="grid max-w-screen-lg grid-cols-12 mx-auto">
      <div class="col-span-12 px-8 sm:col-span-6">
        <h2>What's In The Audit Report</h2>
        <TitleLine position="left" colour="blue-light" />

        <p>
          Once HYDN has completed the security assessment, each issue is
          assigned an impact rating. This impact rating is based upon both the
          severity and likelihood of the issue occurring. Each security
          assessment is different and the business needs and goals, such as
          project timelines, and client threat modelling. are taken into account
          when the impact rankings are created.
        </p>
        <p>
          HYDN assigns the following impact rankings:
          <span class="font-black"
            >Critical, High, Medium, Low, Informational</span
          >.
        </p>
        <p>
          HYDN will also include recommended remediations for your team in the
          report.
        </p>
      </div>
      <div class="col-span-12 px-8 md:px-24 sm:col-span-6">
        <img
          src="@/assets/images/security/audit-report.webp"
          alt="Smart Contract Audit Report"
          class="px-12 mx-auto"
          width="1223"
          height="1567"
        />
      </div>
    </div>
  </div>
  <!-- End Audit Report -->

  <!-- Audit Report -->
  <div
    id="audit-report"
    class="mx-auto text-left bg-white text-grey-dark section container-full block-bottom block-top"
  >
    <div class="grid max-w-screen-xl grid-cols-12 mx-auto">
      <div class="order-2 col-span-12 px-8 md:px-24 sm:col-span-6 sm:order-1">
        <img
          src="@/assets/images/security/hydn-badge.webp"
          alt="HYDN Badge"
          class="mx-auto"
          width="370"
          height="370"
        />
      </div>
      <div class="order-1 col-span-12 pl-8 pr-4 sm:col-span-6 sm:order-2s">
        <h2>What You'll Get</h2>
        <TitleLine position="left" colour="blue-light" />

        <p>
          Alongside the completed smart contract audit report, you will also
          receive a number of marketing tools which you can use to showcase that
          you have successfully completed an audit.
        </p>
        <p>You will recieve:</p>
        <ul class="font-black list-disc">
          <li>Audited by HYDN badge</li>
          <li>Promoted Twitter post</li>
          <li>Audit PDF</li>
        </ul>
        <p>Public audit reports are listed on:</p>
        <ul>
          <li>HYDN website</li>
          <li>HYDN GitHub</li>
          <li>HYDN Medium pages</li>
          <li>HYDN social media channels</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Audit Report -->

  <!-- Bottom CTA  -->
  <div
    class="px-8 py-8 mx-auto sm:py-16 md: sm:px-16 md:px-24 bg-blue container-full"
  >
    <div class="grid max-w-screen-md grid-cols-12 mx-auto text-left">
      <div class="col-span-12 sm:col-span-8">
        <h3 class="block text-3xl font-extrabold text-white sm:text-4xl">
          Ready to get a Smart Contract Audit?
        </h3>
      </div>
      <div class="col-span-12 sm:col-span-4"></div>
      <div class="col-span-12 sm:col-span-8">
        <span class="block text-xl font-bold text-white sm:mr-8 sm:text-2xl">
          Fill out our quick form and one of our team of experts will come back
          to you as soon as possible to discuss your project
        </span>
      </div>
      <div class="col-span-12 mt-4 sm:col-span-4 sm:mt-0">
        <router-link to="/contact?q=smart-contract-audit" class="block button"
          >Request a Quote</router-link
        >
      </div>
    </div>
  </div>
  <!-- End Bottom CTA  -->

  <!-- The Team -->
  <div
    id="mission"
    class="container text-left section block-top block-bottom bg-blue-background sm:text-center"
  >
    <div class="grid grid-cols-1 gap-8 mt-16 text-left team gap-y-0">
      <!-- Team Card -->
      <div v-for="TeamCard in TeamCards" :key="TeamCard.id" class="grid">
        <div class="flex justify-center">
          <div
            class="grid w-full grid-cols-12 gap-4 rounded-xl md:gap-x-8 gap-y-0"
          >
            <div class="col-span-12 md:col-span-6">
              <h2 class="text-left">Mission</h2>
              <TitleLine position="left" colour="blue-light" />
            </div>
            <div class="col-span-12 md:col-span-6"></div>
            <div
              class="order-2 col-span-12 mt-8 md:mt-4 md:col-span-6 md:order-1"
            >
              <div class="pb-8 text-xl">
                “Our mission is to ensure the blockchain supports secure
                business operations for all. I have built the team at HYDN from
                the ground up to meet this very personal objective.”
              </div>

              <div class="grid grid-cols-12">
                <h4 class="col-span-12 mb-4">
                  {{ TeamCard.name }}
                </h4>

                <div
                  v-html="TeamCard.bio"
                  class="col-span-12 md:col-span-12"
                ></div>
                <div
                  v-html="TeamCard.quote"
                  class="col-span-12 md:col-span-12"
                ></div>
                <a
                  v-if="TeamCard.twitter"
                  :href="TeamCard.twitter"
                  target="_blank"
                  type="button"
                  class="col-span-2 sm:col-span-1 icon"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/icons/twitter.svg"
                    alt="twitter"
                    width="48"
                    height="48"
                  />
                </a>
                <a
                  v-if="TeamCard.telegram"
                  :href="TeamCard.telegram"
                  target="_blank"
                  type="button"
                  class="col-span-2 sm:col-span-1 icon"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/icons/telegram.svg"
                    alt="telegram"
                    width="48"
                    height="48"
                  />
                </a>
              </div>
            </div>
            <div
              v-if="TeamCard.image"
              class="order-1 col-span-12 md:col-span-6 md:order-2"
            >
              <img
                class="shadow-xl rounded-2xl"
                :src="require('@/' + TeamCard.image)"
                alt="team"
                width="1477"
                height="1588"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Team Card -->
  </div>
  <!-- End The Team -->

  <!-- Featured Audits -->
  <div
    id="services"
    class="hidden text-center bg-white text-grey-dark section container-full block-top block-bottom"
  >
    <h2>Featured Audits</h2>
    <TitleLine position="center" colour="blue-light" />
    <!-- Featured Audits Loop -->
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 mt-16 text-center sm:container sm:gap-8 sm:mx-auto"
    >
      <div
        v-for="FeaturedAudit in FeaturedAudits"
        :key="FeaturedAudit.id"
        class="col-span-12 md:col-span-4"
      >
        <div
          class="flex flex-col px-8 py-16 text-white rounded-none shadow-2xl sm:px-12 md:rounded-md bg-blue-background"
        >
          <h5 class="mb-2 font-extrabold">
            {{ FeaturedAudit.title }}
          </h5>
          <p class="flex-1">
            {{ FeaturedAudit.subtitle }}
          </p>
          <router-link to="/contact" class="button button__large"
            >{{ FeaturedAudit.cta }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- End Featured Audits loop -->
  </div>
  <!-- End Featured Audits -->

  <!-- Delivery Team -->
  <div
    id="delivery"
    class="container-full mx-auto bg-white text-grey-dark bg-[url('@/assets/images/global/world-map.svg')] bg-left bg-contain bg-no-repeat"
  >
    <div class="grid grid-cols-7 gap-0 mx-auto section">
      <div
        class="min-h-[45rem] sm:col-span-3 md:col-span-3 lg:col-span-4 hidden md:grid"
      ></div>

      <div
        class="text-left p-8 sm:p-12 md:p-12 lg:p-24 col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-3 flex justify-center items-center rounded-4xl bg-white md:bg-white/70 backdrop-blur-lg max-w-[56rem] border border-white"
      >
        <div>
          <h2 class="text-center md:text-left">Delivery Team</h2>
          <TitleLine
            position="left"
            colour="blue-light"
            class="line-left-mobile-center"
          />
          <p>
            The HYDN delivery team has extensive experience in the cybersecurity
            and blockchain space, developing security systems for multinationals
            and tech startups. The team has several years of experience in
            building as well as securing DeFi protocols with the value of over
            $100M.
          </p>
          <p>
            The delivery team is a truly global team with members spread
            throughout the UK, Denmark, France, Portugal, and Spain to cover a
            range of languages, time zones, and ways of working to provide the
            best possible service to our clients.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- End Delivery Team -->

  <!-- Partners -->
  <div
    id="Partners"
    class="text-left bg-white text-grey-dark section container-full block-top block-bottom sm:text-center"
  >
    <h2 class="text-center">Partners</h2>
    <TitleLine position="center" colour="blue-light" />
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto sm:text-center"
    >
      <!-- Partners card -->
      <div
        v-for="PartnersCard in PartnersCards"
        :key="PartnersCard.id"
        class="grid col-span-12 md:col-span-6 lg:col-span-4"
      >
        <div class="grid grid-cols-1">
          <a
            :href="PartnersCard.ctaURL"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              class="content-center px-8 py-8 text-white duration-300 rounded-md sm:px-12 hover:-mt-1 hover:shadow-xl bg-blue-background hover:bg-blue justify-items-center"
            >
              <img
                class="mx-auto"
                :src="require('@/' + PartnersCard.logo)"
                width="600"
                height="200"
                alt="partner"
              />
            </div>
          </a>
        </div>
      </div>
      <!-- End Partners card -->
    </div>
  </div>
  <!-- End Partners -->

  <!-- Why HYDN -->
  <div
    id="why-hydn"
    class="container px-4 text-left text-white section block-top block-bottom bg-blue-background sm:text-center"
  >
    <h2 class="text-center">Why HYDN</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 gap-2 mx-auto sm:container">
      <!-- Why HYDN card -->
      <div
        v-for="WhyHYDNCard in WhyHYDN"
        :key="WhyHYDNCard.id"
        :class="{
          'sm:col-start-4 lg:col-start-0 col-span-12': WhyHYDNCard.id === 3,
        }"
        class="col-span-12 lg:col-span-4 sm:col-span-6"
      >
        <div class="flex justify-center text-left sm:text-center">
          <div class="block text-white md:p-8 md:rounded-xl sm:grid">
            <img
              class="block float-left w-12 h-12 mb-32 mr-4 sm:mb-6 sm:float-none sm:mx-auto"
              src="@/assets/icons/tick.svg"
              alt="tick"
              width="16"
              height="16"
            />
            <h3 class="mb-2 text-3xl">
              {{ WhyHYDNCard.title }}
            </h3>
            <div class="flex-1">
              <p class="text-base">
                {{ WhyHYDNCard.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Why HYDN card -->
  </div>
  <!-- End Why HYDN -->

  <!-- FAQ's -->
  <div
    id="faqs"
    class="px-6 text-left text-white container-full section block-top block-bottom bg-blue-dark sm:text-center"
  >
    <h2 class="text-center">Common Questions</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 gap-2 mx-auto sm:container">
      <!-- FAQ card -->
      <div
        v-for="SecurityFAQ in SecurityFAQs"
        :key="SecurityFAQ.id"
        class="col-span-12 md:max-w-screen-md md:col-start-2"
      >
        <div class="flex justify-center text-left">
          <div class="block pb-4 text-white md:px-8 md:rounded-xl sm:grid">
            <h3 class="mb-2 text-3xl">
              {{ SecurityFAQ.question }}
            </h3>
            <div class="flex-1">
              <p class="text-base" v-html="SecurityFAQ.answer"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End FAQ card -->
  </div>
  <!-- End FAQ's -->
</template>

<script>
import ServiceCards from "../data/Security/ServiceCards.json";
import FeaturedAudits from "../data/Security/FeaturedAudits.json";
import DetailedAudits from "../data/Security/DetailedAudits.json";
import AuditProcess from "../data/Security/AuditProcess.json";
import TeamCards from "../data/Security/TeamCards.json";
import PartnersCards from "../data/Global/PartnersCards.json";
import WhyHYDN from "../data/Security/WhyHYDN.json";
import SecurityFAQs from "../data/Security/SecurityFAQs.json";
import Costs from "../data/Security/Costs.json";
import TitleLine from "../components/utilities/TitleLine.vue";
import { Vue3Lottie } from "vue3-lottie";

import * as SecurityHero from "../assets/images/security/lottie-security-hero.json";
const url = "/api/availabilities";
export default {
  components: {
    TitleLine,
    Vue3Lottie,
  },
  data() {
    return {
      DetailedAudits: DetailedAudits,
      FeaturedAudits: FeaturedAudits,
      ServiceCards: ServiceCards,
      AuditProcess: AuditProcess,
      TeamCards: TeamCards,
      PartnersCards: PartnersCards,
      WhyHYDN: WhyHYDN,
      SecurityFAQs: SecurityFAQs,
      availabilities: [],
      Costs: Costs,

      SecurityHero,
      animationSpeed: 1,
    };
  },
  mounted() {
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((parsed) => {
        // console.info("parsed", parsed);
        this.availabilities = parsed.data;
      })
      .catch((error) => {
        console.error("Failed to fetch availabilities", error);
      });
  },
};
</script>

<style scoped></style>
