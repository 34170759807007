<template>
  <!-- Hero -->
  <div id="careers" class="container section container-full">
    <div class="grid grid-cols-12 my-16 sm:gap-8 md:gap-24 md:my-36">
      <div class="col-span-12 text-left md:col-span-7">
        <h1>Careers</h1>
        <TitleLine position="left" colour="blue-light" />
        <div class="mt-8 mb-12 text-md md:text-lg lg:text-xl">
          HYDN believes that the best way to succeed in a fast-moving industry
          is by hiring the very best people
        </div>
        <div class="sm:flex">
          <a href="#open-positions" class="button hero">Open Positions</a>
        </div>
      </div>

      <div class="hidden mx-auto md:col-span-5 md:flex items-center">
        <div>
          <Vue3Lottie :animationData="CareersHero" />
        </div>
      </div>
    </div>
  </div>
  <!-- End Hero -->

  <!-- About Careers Verification -->
  <div
    id="about-careers"
    class="text-center bg-white text-grey-dark container-full section block-top block-bottom"
  >
    <div
      class="grid content-center max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto"
    >
      <div
        v-if="AboutCareers.image"
        class="items-center hidden col-span-12 pr-8 mt-4 md:col-span-6 lg:col-span-4 md:flex"
      >
        <img class="" :src="require('@/' + AboutCareers.image)" alt="career" />
      </div>

      <div class="col-span-12 mt-8 md:mt-0 md:col-span-6 lg:col-start-7">
        <h2 class="text-left">{{ AboutCareers.title }}</h2>
        <TitleLine position="left" colour="blue-light" />
        <div class="grid grid-cols-12">
          <div
            v-html="AboutCareers.content"
            class="col-span-12 md:col-span-12"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- End About Careers Verification -->

  <!-- About Careers Importance -->
  <div
    id="open-positions"
    class="text-center text-white bg-blue-background container-full section block-top"
  >
    <div
      class="grid max-w-screen-xl grid-cols-12 gap-2 mx-4 text-left sm:container sm:gap-8 sm:mx-auto"
    >
      <div class="col-span-12 mt-8 md:mt-0 md:col-span-8 md:col-start-3">
        <h2 class="text-center">{{ CareersContent.title }}</h2>
        <TitleLine position="center" colour="blue-light" />

        <!-- Careers card
     Json used to populate the cards

      {
        "id": 1,
        "title": "Role 1",
        "content": "Example open position text info text info text info text info text info text info text info text info text info text info text info text info text info text info text info text info text info text info text info text info text",
        "url": "https://www.google.com"
    }

    -->
        <div
          v-for="CareersListingsCard in CareersListings"
          :key="CareersListingsCard.id"
          class="col-span-12 md:flex"
        >
          <div
            class="col-span-12 col-start-1 px-6 py-8 mb-6 text-left bg-white md:col-start-4 rounded-xl text-blue-background"
          >
            <h3 class="mb-4 text-2xl sm:text-2xl lg:text-3xl">
              {{ CareersListingsCard.title }}
            </h3>
            <div v-html="CareersListingsCard.content"></div>

            <a
              :href="CareersListingsCard.url"
              rel="noopener noreferrer"
              target="_blank"
              class="block button sm:inline-block sm:align-middle"
              >Apply Now</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- End Careers card -->
    <div v-if="CareersListings.length === 0" class="">
      <div
        class="col-span-5 px-12 pt-12 pb-8 mt-12 mb-6 text-center bg-white text-blue-background"
      >
        <h3 class="mb-4 text-2xl sm:text-2xl lg:text-3xl">No Open Positions</h3>
      </div>
    </div>
  </div>
  <!-- End About Careers Importance -->

  <!-- Banner -->

  <div
    class="px-16 py-8 items-center text-center bg-blue border-2 border-x-0 border-y-white"
  >
    <h5>{{ CareersContent.disclaimer }}</h5>
  </div>
  <!-- End Banner -->

  <!-- End Verification Process -->

  <!-- Why HYDN -->
  <div
    id="why-hydn"
    class="container px-4 text-left text-white section block-top block-bottom bg-blue-background sm:text-center"
  >
    <h2 class="text-center">Why HYDN</h2>
    <TitleLine position="center" colour="blue-light" />
    <div class="grid grid-cols-12 gap-2 mx-auto sm:container">
      <!-- Why HYDN card -->
      <div
        v-for="WhyHYDNCard in WhyHYDN"
        :key="WhyHYDNCard.id"
        :class="{
          'sm:col-start-4 lg:col-start-0 col-span-12': WhyHYDNCard.id === 3,
        }"
        class="col-span-12 lg:col-span-4 sm:col-span-6"
      >
        <div class="flex justify-center text-left sm:text-center">
          <div class="block text-white md:p-8 md:mb-4 md:rounded-xl sm:grid">
            <img
              class="block float-left w-12 h-12 mb-32 mr-4 sm:mb-6 sm:float-none sm:mx-auto"
              src="@/assets/icons/tick.svg"
              alt="tick"
            />
            <h3 class="mb-2 text-3xl">
              {{ WhyHYDNCard.title }}
            </h3>
            <div class="flex-1">
              <p class="text-base">
                {{ WhyHYDNCard.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Why HYDN card -->
  </div>
  <!-- End Why HYDN -->
  <!-- Bottom CTA  -->
  <div class="py-4 bg-white container-full">
    <div class="px-8 py-12 mx-auto text-center">
      <span
        class="block pb-8 text-3xl font-extrabold text-blue-background sm:text-4xl md:pr-8 md:pb-0 md:inline-block sm:align-middle"
        >Looking for a career with us?</span
      >
      <router-link
        to="/contact"
        class="block button hero sm:inline-block sm:align-middle"
        >Contact Us</router-link
      >
    </div>
  </div>
  <!-- End Bottom CTA  -->
</template>

<script>
import CareersListings from "../data/Careers/CareersListings.json";
import AboutCareers from "../data/Careers/AboutCareers.json";
import CareersContent from "../data/Careers/CareersContent.json";
import WhyHYDN from "../data/Careers/WhyHYDN.json";

import TitleLine from "../components/utilities/TitleLine.vue";
import { Vue3Lottie } from "vue3-lottie";

import * as CareersHero from "../assets/images/careers/lottie-careers-hero.json";

export default {
  components: {
    TitleLine,
    Vue3Lottie,
  },
  data() {
    return {
      CareersListings: CareersListings,
      AboutCareers: AboutCareers,
      CareersContent: CareersContent,
      WhyHYDN: WhyHYDN,

      CareersHero,
      animationSpeed: 1,
    };
  },
};
</script>

<style scoped lang="scss">
.icon {
  @apply w-full;
  img {
    @apply invert-[.30] duration-300 hover:invert-0 w-full max-h-[42px];
  }
}
</style>
