<template>
  <div class="flex items-center justify-center bg-blue-background py-40">
    <div class="p-8 bg-white rounded-md shadow-xl">
      <div class="flex flex-col items-center">
        <h1 class="font-extrabold text-grey-dark text-9xl">Upload file</h1>
        <div class="w-full bg-white text-grey-dark p-8 md:my-0 rounded-md">
          <Upload />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Upload from "../components/Upload.vue";

export default {
  components: {
    Upload,
  },
};
</script>
<style lang="scss">
.form {
  &__input,
  &__select {
    @apply block w-full px-4 py-2 mt-2 text-xl placeholder-grey-light border border-grey-light rounded-lg duration-300 focus:outline-blue-light focus:ring-4 focus:ring-blue-light focus:ring-opacity-40;
  }
  &__textarea {
    @apply block w-full h-36 px-4 py-2 mt-2 text-xl placeholder-grey-light border border-grey-light rounded-lg duration-300 focus:outline-blue-light focus:ring-4 focus:ring-blue-light focus:ring-opacity-40;
  }
}
.required {
  label {
    &:first-child {
      &:after {
        @apply text-red ml-1;
        content: "*";
      }
    }
  }
}
</style>
