<template>
  <section class="container my-24">
    <div class="w-full my-20 text-grey-dark bg-white md:my-0">
      <div class="w-full px-4 py-8 sm:p-10 lg:p-16 xl:p-24">
        <h2 class="mb-0 text-5xl font-extrabold xl:text-6xl">
          {{ PrivacyPolicy.title }}
        </h2>
        <TitleLine position="left" colour="blue-light" />
        <p v-html="PrivacyPolicy.content" class="text-base"></p>
      </div>
    </div>
  </section>
</template>

<script>
import TitleLine from "../components/utilities/TitleLine.vue";
import PrivacyPolicy from "../data/PrivacyPolicy.json";

export default {
  data() {
    return {
      PrivacyPolicy: PrivacyPolicy,
    };
  },
  components: {
    TitleLine,
  },
};
</script>
