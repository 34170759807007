<template>
  <footer
    class="container text-white footer container-full block-top bg-blue-background"
  >
    <div class="block">
      <div class="grid grid-cols-12">
        <div class="inline-block float-left col-span-12 mb-8 sm:col-span-3">
          <router-link to="/" class="text-4xl font-extrabold remove-underline">
            <img
              class=""
              src="@/assets/images/hydn-logo.svg"
              width="250"
              height="44"
              alt="hydnsec logo"
              loading="lazy"
            />
          </router-link>
        </div>
        <div
          class="col-span-12 text-left sm:float-right sm:col-span-3 sm:col-start-8"
        >
          <div class="mb-6 socials">
            <a
              :href="data.twitterURL"
              target="_blank"
              type="button"
              class="icon"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/icons/twitter.svg"
                alt="twitter"
                width="48"
                height="48"
                loading="lazy"
              />
            </a>

            <a
              :href="data.discordURL"
              target="_blank"
              type="button"
              class="hidden icon"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/icons/discord.svg"
                alt="discord"
                width="48"
                height="48"
                loading="lazy"
              />
            </a>

            <a
              :href="data.telegramURL"
              target="_blank"
              type="button"
              class="icon"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/icons/telegram.svg"
                alt="telegram"
                width="48"
                height="48"
                loading="lazy"
              />
            </a>
            <a
              :href="data.githubURL"
              target="_blank"
              type="button"
              class="icon"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/icons/github.svg"
                alt="github"
                width="48"
                height="48"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12">
      <div class="col-span-12 my-6 sm:col-span-4">
        <h4 class="mb-4">Services</h4>
        <ul>
          <li class="flex list-none">
            <router-link to="security" class="px-8 py-2 nav__link"
              >Security Audit</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="hydnseekers" class="px-8 py-2 nav__link"
              >HYDN Seekers</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="tokenomics" class="px-8 py-2 nav__link"
              >Tokenomics</router-link
            >
          </li>
        </ul>
      </div>

      <div class="col-span-12 my-6 sm:col-span-4">
        <h4 class="mb-4">Links</h4>
        <ul>
          <li class="flex list-none">
            <router-link to="/partners" class="px-8 py-2 nav__link"
              >Partners</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="/blog" class="px-8 py-2 nav__link"
              >Blog</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="/careers" class="px-8 py-2 nav__link"
              >Careers</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="/about" class="px-8 py-2 nav__link"
              >About Us</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col-span-12 my-6 sm:col-span-4">
        <h4 class="mb-4">HYDN</h4>
        <ul>
          <li class="flex list-none">
            <router-link to="/contact" class="px-8 py-2 nav__link"
              >Contact Us</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="/terms-and-conditions" class="px-8 py-2 nav__link"
              >Terms and Conditions</router-link
            >
          </li>
          <li class="flex list-none">
            <router-link to="/privacy-policy" class="px-8 py-2 nav__link"
              >Privacy Policy</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="mt-12 text-sm text-center text-grey-light">
      <p>{{ data.companyAddress }}</p>
      <p>
        <a
          class="lowercase"
          :href="'mailto:' + data.companyEmail"
          rel="noreferrer"
          >{{ data.companyEmail }}</a
        >
      </p>
      <p>
        © {{ new Date().getFullYear() }} - <span>{{ data.companyName }}</span>
      </p>
    </div>
  </footer>
</template>

<script>
import * as companyData from "@/globals.js";

export default {
  data() {
    return {
      routes: this.$router.options.routes,
      data: companyData,
    };
  },
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>

<style lang="scss">
.nav__link {
  @apply px-0;
}
.icon {
  @apply mr-8 xl:mx-6 h-8 w-8 xl:h-12 xl:w-12;
}
.icon img {
  @apply invert-[.80] duration-300 hover:invert;
}
footer {
  li a,
  h4 {
    @apply capitalize;
  }
}
.socials {
  @apply sm:justify-center;
  display: flex;
  a {
    @apply max-w-fit;
  }
}
</style>
