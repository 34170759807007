import Home from "./views/Home.vue";
/** @type {import('vue-router').RouterOptions['routes']} */

export const routes = [
  {
    path: "/",
    component: Home,
    text: "Home",
    meta: { title: "Web3 Security and Consultancy Experts" },
  },
  {
    type: "internal",
    path: "/security",
    text: "Blockchain Security",
    meta: { title: "Smart Contract Audit Experts", gtm: "Security" },
    component: () => import("./views/Security"),
  },
  {
    type: "internal",
    path: "/kyc-verification",
    text: "KYC Verification",
    meta: { title: "KYC Verification", gtm: "KYC Verification" },
    component: () => import("./views/KYC"),
  },
  {
    type: "internal",
    path: "/tokenomics",
    text: "Tokenomics",
    meta: { title: "Web3 Consultancy Experts", gtm: "Tokenomics" },
    component: () => import("./views/Tokenomics"),
  },
  {
    type: "internal",
    path: "/hydnseekers",
    text: "HYDN Seekers",
    meta: { title: "Web3 Vulnerability Protection", gtm: "HYDN Seekers" },
    component: () => import("./views/HydnSeekers"),
  },
  {
    type: "internal",
    path: "/completed-audits",
    text: "Completed Audits",
    meta: { title: "Completed Audits", gtm: "Completed Audits" },
    component: () => import("./views/CompletedAudits"),
  },
  {
    type: "internal",
    path: "/about",
    text: "About",
    meta: { title: "About", gtm: "About" },
    component: () => import("./views/About"),
  },
  {
    type: "internal",
    path: "/blog",
    text: "Blog",
    meta: { title: "Blog", gtm: "Blog" },
    component: () => import("./views/Blog"),
  },
  {
    type: "internal",
    path: "/careers",
    text: "Careers",
    meta: { title: "Careers", gtm: "Careers" },
    component: () => import("./views/Careers"),
  },
  {
    type: "internal",
    path: "/partners",
    text: "Partners",
    meta: { title: "Partners", gtm: "Partners" },
    component: () => import("./views/Partners"),
  },
  {
    type: "cta",
    path: "/contact",
    text: "Request a Quote",
    meta: { title: "Request a Quote", gtm: "RequestAQuote" },
    component: () => import("./views/Contact"),
  },
  {
    type: "internal",
    path: "/terms-and-conditions",
    text: "Terms and Conditions",
    meta: { title: "Terms and Conditions", gtm: "TermsAndConditions" },
    component: () => import("./views/TermsAndConditions"),
  },
  {
    type: "internal",
    path: "/privacy-policy",
    text: "Privacy Policy",
    meta: { title: "Privacy Policy", gtm: "PrivacyPolicy" },
    component: () => import("./views/PrivacyPolicy"),
  },
  {
    type: "internal",
    path: "/upload",
    text: "Upload",
    meta: { title: "Upload", gtm: "Upload" },
    component: () => import("./views/Upload"),
  },
  { path: "/:path(.*)", component: () => import("./views/NotFound") },
];
