<template>
  <div :class="'line line-' + position + ' bg-' + colour"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "Line",
  props: {
    position: String,
    colour: String,
  },
};
</script>

<style scoped lang="scss">
.line {
  @apply mt-4 mb-8 w-24 h-1;
  &-left {
    &-mobile-center {
      @apply mx-auto md:mx-0;
    }
  }
  &-center {
    @apply mx-auto;
  }
}
</style>
